class Language {

  twoLetterCode: string;
  fourLetterCode: string;

  constructor(twoLetterCode: string, fourLetterCode: string) {
    this.twoLetterCode = twoLetterCode;
    this.fourLetterCode = fourLetterCode;
  }

}

export default Language;