import React from "react";
import {doesContainDigitalProduct} from './customHelperFunctions';
import Currencies from "src/types/Currencies";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ShippingCostSelector = (props) => {
    const shipping = props.shipping;
    const cartItems = props.cartItems;
    const currency = Currencies.getCurrentCurrency();
    const {t} = useTranslation();
    if(doesContainDigitalProduct(cartItems) === false) {
         return (
              <Row className="mt-1">
                   <Col>{t('shipping')}</Col>
                   <Col className="text-right">{currency.symbol} {currency.code === "JPY" ? Number(shipping * currency.factor).toFixed(0) : Number(shipping * currency.factor).toFixed(2)}</Col>
              </Row>
         )
    }

    return null;
}

export default ShippingCostSelector;