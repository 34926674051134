import React from 'react';
import { Jumbotron, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function Error404() {

     const { t } = useTranslation();

     return (
          <Container className="main">
               <Jumbotron>
                    <h1><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {t('error_page_title')}</h1>
                    <p>{t('error_page_content')}</p>
                    <Link to="/"><Button variant="primary">{t('continue_shopping')}</Button></Link>
               </Jumbotron>
          </Container>
     )

}

export default Error404;