import React, { useContext, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowIosBackOutline as LeftArrow } from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import APaySignInButton from '../../components/amazon-pay-button/APaySignInButton';
import { useTranslation } from 'react-i18next';
import Languages from 'src/types/Languages';
import { getAnchorLinkPrivacyPolicy } from 'src/helpers/customHelperFunctions';
import CreateSBBuyer from 'src/helpers/CreateSBBuyer';

export default function Account() {
    const { t } = useTranslation();
    const emailAddress = React.useRef(null);
    const password = React.useRef(null);
    const firstName = React.useRef(null);
    const lastName = React.useRef(null);
    const streetAddress = React.useRef(null);
    const city = React.useRef(null);
    const state = React.useRef(null);
    const postalCode = React.useRef(null);
    const country = React.useRef(null);
    const phoneNumber = React.useRef(null);
    const [countryOptions] = React.useState([
        { value: 'US', label: 'United States' },
        { value: 'UK', label: 'United Kingdom' },
        { value: 'GB', label: 'Great Britain' },
        { value: 'DE', label: 'Deutschland' },
        { value: 'FR', label: 'France' },
        { value: 'IT', label: 'Italy' },
        { value: 'ES', label: 'Spain' },
        { value: 'AT', label: 'Austria' },
        { value: 'BE', label: 'Belgium' },
        { value: 'BG', label: 'Bulgaria' },
        { value: 'HR', label: 'Croatia' },
        { value: 'CY', label: 'Cyprus' },
        { value: 'CZ', label: 'Czech Republic' },
        { value: 'DK', label: 'Denmark' },
        { value: 'EE', label: 'Estonia' },
        { value: 'FI', label: 'Finland' },
        { value: 'GR', label: 'Greece' },
        { value: 'HU', label: 'Hungary' },
        { value: 'IE', label: 'Ireland' },
        { value: 'LV', label: 'Latvia' },
        { value: 'LT', label: 'Lithuania' },
        { value: 'LU', label: 'Luxembourg' },
        { value: 'MT', label: 'Malta' },
        { value: 'NL', label: 'Netherlands' },
        { value: 'PL', label: 'Poland' },
        { value: 'PT', label: 'Portugal' },
        { value: 'RO', label: 'Romania' },
        { value: 'SK', label: 'Slovakia' },
        { value: 'SI', label: 'Slovenia' },
        { value: 'SE', label: 'Sweden' },
        { value: 'JP', label: 'Japan' },
    ]);

    const language = Languages.getCurrentLanguage()?.twoLetterCode;

    return (
        <Container className="main"><br />
            <Row className="justify-content-start offset-mt-2">
                <Col className="col-12 col-sm-5">
                    <Row>
                        <Col>
                            <Row>
                                <Col><h3>{t('I_have_account')} </h3></Col>
                            </Row><br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>{t('login_with_email_and_password')}.</Col>
                            </Row><br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>{t('email_address')}*</Col>
                            </Row>
                            <Row>
                                <Col><input type="email" ref={emailAddress} className="form-control" required placeholder={t('enter_email_address')} /></Col>
                            </Row><br />
                            <Row>
                                <Col>{t('password')}*</Col>
                            </Row>
                            <Row>
                                <Col><input type="password" ref={password} className="form-control" required placeholder={t('enter_password')} /></Col>
                            </Row><br />
                            <Row>
                                <Col>{t('forgot_password')}?</Col>
                            </Row><br />
                            <Row>
                                <Col><Button className="text-uppercase">{t('sign_in')}</Button></Col>
                            </Row><br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Section>
                                <Row>
                                    <Col>
                                        <h5>{t('checkout_existing_amz_customer')}</h5>
                                        {t('checkout_skip_the_line')}
                                    </Col>
                                </Row><br />
                                <Row>
                                    <Col>
                                        <SignInContainer>
                                            <APaySignInButton id="ap-button-signin" />
                                            <br />
                                            <CreateSBBuyer/>
                                        </SignInContainer>
                                    </Col>
                                </Row>
                            </Section>
                        </Col>
                    </Row>

                </Col>
                <Col className="col-12 col-sm-7">
                    <Row>
                        <Col className="col-12">
                            <Row>
                                <Col className="col-5"><h3>{t('new_customer')}</h3></Col>
                                <Col className="col-7 text-right">{t('checkout_required_fields')}</Col>
                            </Row><br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col className="col-12 border-bottom font-weight-bold text-uppercase">{t('your_personal_details')}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col className="col-6">{t('first_name')}*</Col>
                        <Col className="col-6">{t('last_name')}*</Col>
                    </Row>
                    <Row>
                        <Col className="col-6"><input type="text" className="form-control" ref={firstName} name="firstName" id="firstName" required placeholder={t('enter_first_name')} /></Col>
                        <Col className="col-6"><input type="text" className="form-control" ref={lastName} name="lastName" id="lastName" required placeholder={t('enter_last_name')} /></Col>
                    </Row><br />
                    <Row>
                        <Col>{t('email_address')}*</Col>
                    </Row>
                    <Row>
                        <Col><input type="email" ref={emailAddress} className="form-control" required placeholder={t('enter_email_address')} /></Col>
                    </Row><br />
                    <Row>
                        <Col>{t('password')}*</Col>
                    </Row>
                    <Row>
                        <Col><input type="password" ref={password} className="form-control" required placeholder={t('enter_password')} /></Col>
                    </Row>
                    <Row>
                        <Col>{t('checkout_password_min_length')}</Col>
                    </Row><br />
                    <Row>
                        <Col className="col-12 border-bottom font-weight-bold text-uppercase"> {language != 'jp' && t('primary')} {t('shipping_address')}</Col>
                    </Row><br />
                    <Row>
                        <Col className="col-6">{t('street_address')}*</Col>
                        <Col className="col-6">{t('city')}*</Col>
                    </Row>
                    <Row>
                        <Col className="col-6"><input ref={streetAddress} type="text" className="form-control" required placeholder={t('enter_street_address')} /></Col>
                        <Col className="col-6"><input ref={city} type="text" className="form-control" required placeholder={t('enter_city')} /></Col>
                    </Row><br />
                    <Row>
                        <Col className="col-6">{t('state_or_region')}</Col>
                        <Col className="col-6">{t('postal_code')}*</Col>
                    </Row>
                    <Row>
                        <Col className="col-6"><input type="text" ref={state} className="form-control" placeholder={t('enter_state_or_region')} /></Col>
                        <Col className="col-6"><input type="text" ref={postalCode} className="form-control" required placeholder={t('enter_postal_code')} /></Col>
                    </Row><br />
                    <Row>
                        <Col className="col-6">{t('country')}</Col>
                        <Col className="col-6">{t('phone_number')}*</Col>
                    </Row>
                    <Row>
                        <Col className="col-6">
                            <select ref={country} className="form-control" name="country" id="country">
                                {countryOptions.map(item => (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col className="col-6"><input type="text" ref={phoneNumber} className="form-control" required placeholder={t('enter_phone_number')} /></Col>
                    </Row><br />
                    <Row>
                        <Col>
                            <input type="checkbox" style={{ border: 'red' }} placeholder={t('enter_last_name')} />
                            &nbsp; {t('checkout_billing_same_as_shipping')}
                        </Col>
                    </Row><br />
                    <Row>
                        <Col className="font-weight-bold">{t('privacy')}</Col>
                    </Row>
                    <Row>
                        <Col>{t('I_have_read_and_agree')} <a href={getAnchorLinkPrivacyPolicy(language)} target="_blank">{t('privacy_notice')}.</a></Col>
                    </Row><br />
                    <Row>
                        <Col><ContinueButton>{t('continue')}</ContinueButton></Col>
                    </Row>

                </Col>
            </Row>

        </Container>
    )
}


const Arrow = styled(LeftArrow)`
    color: black;
    height: 30px;
    width: 30px;
`;
const Anchor = styled(Link)`
    color: black;
    text-color: black;
    font-weight: bold;
`;
const Section = styled.section`
  color: black;

  /* Pass variables as inputs */
  padding: 1rem;
  /* Adjust the background from the properties */
  border: 1px solid #dee2e6 !important;
  background-color: #f8f9fa !important;
`;
const SignInContainer = styled.section`
  height: 115px;
  width: 350px;
`;
const Button = styled.button`
    background: black;
    color:  white;
    text-color: white;
    width: 100%;
    height: 44px;
    font-size: 1em;
    border: 1px solid black;
    border-radius: 3px;
`;
const ContinueButton = styled.button`
    background: black;
    color:  white;
    text-color: white;
    width: 214px;
    height: 44px;
    font-size: 1em;
    border: 1px solid black;
    border-radius: 3px;
    text-transform: uppercase;
`;