import React from "react";
import { NavDropdown, Navbar, Container } from 'react-bootstrap';
import Flex from "./flex";
import i18n from 'src/i18n';
import * as KatalMetrics from '@amzn/katal-metrics';
import initialMetricsPublisher from 'src/metrics';
import Currencies from '../../types/Currencies';
import Languages from '../../types/Languages';
import useLocalStorage from '../../custom-hooks/useLocalStorage';
import { refreshPage } from 'src/helpers/customHelperFunctions';

// not sure why, but sometimes i18n doesn't init with the language that was set in the past - explicitly do this here
let currentLanguage = Languages.getCurrentLanguage();
i18n.changeLanguage(currentLanguage.fourLetterCode);

export type eventKey = string | null;

const LanguageNav = () => {

     const [region, setRegion] = useLocalStorage('region', 'us');
     const [language, setLanguage] = React.useState<eventKey>(currentLanguage.twoLetterCode.toUpperCase());

     // currency related functionality (init + hook)
     let currencyObject = Currencies.getCurrentCurrency();
     const [currency, setCurrency] = React.useState<eventKey>(`${currencyObject.symbol} ${currencyObject.code}`);

     const handleLanguageChange = React.useCallback((eventKey: eventKey) => {
          let language = Languages.getLanguageFromLanguageCode(eventKey || Languages.English.twoLetterCode);
          setLanguage(eventKey);
          changeLanguage(language.fourLetterCode);
          if ((currencyObject.code === "GBP" || currencyObject.code === "EUR") && language.fourLetterCode === "ja-JP") {
               changeLanguage("en-US");
          }
          refreshPage();
     }, []);

     function changeLanguage(language: string) {
          const localeChangeMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod("LocaleChange");
          const localeChangeHandlerMetric = new KatalMetrics.Metric.TimedAttempt("Translation").withMonitor();
          localeChangeMetricsPublisher.publish(new KatalMetrics.Metric.String("locale", language || "NONE"));
          i18n.changeLanguage(language, (err, t) => {
               if (err) {
                    localeChangeHandlerMetric.setFailure();
               } else {
                    localeChangeHandlerMetric.setSuccess();
               }
               localeChangeMetricsPublisher.publish(localeChangeHandlerMetric);
          });
     }

     const handleCurrencyChange = React.useCallback((eventKey: eventKey) => {
          // If page contains 'review' (ie inside review page), do not perform hangleCurrencyChange
          if (!window.location.hash.includes('review')) {
               setCurrency(eventKey);
               if (eventKey != null) {

                    // TODO: consider splitting eventKey into proper object for not having to use a hack like substr to get the currency code
                    const currencyCode = eventKey.toString().substr(2, 3);
                    Currencies.setCurrentCurrency(currencyCode);

                    // simplification as we aren't using multi-currency yet
                    const region = (currencyCode == 'EUR' ? 'eu' : (currencyCode == 'GBP' ? 'uk' : (currencyCode == 'JPY' ? 'jp' : 'us')));
                    setRegion(region);

                    // reload the page so that the currency change is reflected
                    window.location.reload();
               }
          }
     }, []);

     return (
          <>
               <div id="triangle-topleft"></div>
               {/* ToDo: Add String Translation - For Demo Store  */}
               <div id="test-site-logo">Demo Store</div>
               {!/review|confirm/.test(window.location.hash) && <Navbar className="navbar-top" fixed="top">
                    <Container>
                         <Flex justify="flex-end" padding="0 20px 0 0">
                              <NavDropdown
                                   onSelect={handleLanguageChange}
                                   alignRight
                                   title={language}
                                   id="basic-nav-dropdown"
                              >
                                   <NavDropdown.Item eventKey={Languages.English.twoLetterCode.toUpperCase()}>{Languages.English.twoLetterCode.toUpperCase()}</NavDropdown.Item>
                                   <NavDropdown.Item eventKey={Languages.German.twoLetterCode.toUpperCase()}>{Languages.German.twoLetterCode.toUpperCase()}</NavDropdown.Item>
                                   <NavDropdown.Item eventKey={Languages.French.twoLetterCode.toUpperCase()}>{Languages.French.twoLetterCode.toUpperCase()}</NavDropdown.Item>
                                   <NavDropdown.Item eventKey={Languages.Italian.twoLetterCode.toUpperCase()}>{Languages.Italian.twoLetterCode.toUpperCase()}</NavDropdown.Item>
                                   <NavDropdown.Item eventKey={Languages.Spanish.twoLetterCode.toUpperCase()}>{Languages.Spanish.twoLetterCode.toUpperCase()}</NavDropdown.Item>
                                   <NavDropdown.Item eventKey={Languages.Japanese.twoLetterCode.toUpperCase()}>{Languages.Japanese.twoLetterCode.toUpperCase()}</NavDropdown.Item>
                              </NavDropdown>
                              <NavDropdown
                                   onSelect={handleCurrencyChange}
                                   alignRight
                                   title={currency}
                                   id="basic-nav-dropdown"
                              >
                                   <NavDropdown.Item eventKey="$ USD">$ USD</NavDropdown.Item>
                                   <NavDropdown.Item eventKey="€ EUR">€ EUR</NavDropdown.Item>
                                   <NavDropdown.Item eventKey="£ GBP">£ GBP</NavDropdown.Item>
                                   <NavDropdown.Item eventKey="¥ JPY">¥ JPY</NavDropdown.Item>
                              </NavDropdown>
                         </Flex>
                    </Container>
               </Navbar>}
               {/review|confirm/.test(window.location.hash) && <Navbar className="navbar-top" fixed="top">
                    <Navbar.Text> </Navbar.Text>
               </Navbar>}
          </>
     );
};

export default LanguageNav;