export default class UrlHelper {

    private static _searchField: string = document.location.search;

    protected static set searchField(value: string) {
        UrlHelper._searchField = value;
    };

    protected static get searchField(): string {
        return UrlHelper._searchField;
    };

    public static getUrlParam(name: string): string {
        let search = this.getUrlParams();
        return search.get(name) || "";
    }
    
    private static getUrlParams(): URLSearchParams {
        if (!UrlHelper._searchField) return new URLSearchParams();
        return new URLSearchParams(UrlHelper._searchField);
    }

}