import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Image, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { ArrowIosBackOutline as LeftArrow } from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import AmazonPayButton from '../../components/amazon-pay-button/AmazonPayButton';
import Currencies from '../../types/Currencies';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../contexts/CartContext';
import UrlHepler from '../../helpers/UrlHelper';
import { refreshPage } from 'src/helpers/customHelperFunctions';
import OrderSummary from 'src/helpers/OrderSummary';
import CreateSBBuyer from 'src/helpers/CreateSBBuyer';

export default function Cart() {

    const { t } = useTranslation();
    const { increase, decrease, removeProduct, clearCart } = useContext(CartContext);
    const orderDetails = useContext(CartContext);
    const cartItems = orderDetails.cartItems;
    const currency = Currencies.getCurrentCurrency();
    const [alertShown, showAlert] = useState(false);

    // Checks if QueryParameter has ap-error tag to show an error dialogue
    const alertMessageAPError = new URLSearchParams(useLocation().search).get("ap-error") ?? '';

    function AlertDismissibleExample() {
        return (
            <Alert variant="danger">
                {/* TODO: add translations */}
                <Alert.Heading>{t('payment_failed')}</Alert.Heading>
                <p>
                    {t('payment_retry')}
                </p>
            </Alert>
        );
    }

    function AlertCartMessage() {
        const isRecurring = cartItems.find(({ id }) => id === 3) ? true : false;
        const isDigital = cartItems.find(({ id }) => id === 4) ? true : false;
        if (isRecurring && isDigital) {
            return (
                <Alert variant="primary">
                    {t('checkout_experience_recurring_digital')}
                </Alert>
            )
        } else if (isRecurring) {
            return (
                <Alert variant="primary">
                    {t('recurring_checkout_prompt')}
                </Alert>
            )
        } else if (isDigital) {
            return (
                <Alert variant="primary">
                    {t('digital_checkout_prompt')}
                </Alert>
            )
        } else {
            return null;
        }
    }

    useEffect(
        () => {
            let apError = alertMessageAPError;
            if (apError === "CheckoutSessionCanceled") {
                showAlert(true);
            }
        }
    )

    return (
        <Container className="main"><br />

            <Row className="mx-auto">
                <Anchor to="/"><Arrow /> {t('continue_shopping')}</Anchor>
            </Row><br />

            {
                alertShown && <AlertDismissibleExample />
            }

            <Row className="mx-auto">
                <h3>{t('shopping_cart')}</h3>
            </Row>
            <Row>
                <AlertCartMessage />
            </Row>
            {
                cartItems.length > 0 ?
                    <React.Fragment>
                        <Row>
                            <Col className="col-sm-9 col-12">
                                <Row>
                                    <Col className="d-none d-sm-inline col-4 text-uppercase text-center">{t('product')}</Col>
                                    <Col className="d-none d-sm-inline col-3 text-uppercase">&nbsp; {t('quantity')}</Col>
                                    <Col className="d-none d-sm-inline col-2 text-uppercase">{t('item_price')}</Col>
                                    <Col className="d-none d-sm-inline col-3 d-sm-flex justify-content-center text-uppercase">{t('subtotal')}</Col>
                                </Row>
                                <hr />

                                {
                                    cartItems.map(product => (
                                        <React.Fragment key={product.id}>
                                            <Row>
                                                <Col className="col-sm-2 col-4">
                                                    <Image className="img-fluid border" src={product.photo} width="90" height="90" />
                                                </Col>
                                                <Col className="col-sm-2 col-6">
                                                    <b>{t(product?.translationCodeName)}</b>
                                                </Col>
                                                <Col className="d-sm-none d-inline col-1">
                                                    <button
                                                        onClick={() => { removeProduct(product); refreshPage(); }}
                                                        className="btn btn-sm">
                                                        <i className="fa fa-window-close fa-2x" aria-hidden="true"></i>
                                                    </button>
                                                </Col>
                                                <Col className="d-sm-none d-inline d-flex justify-content-end col-sm-3 pr-4 col-8">
                                                    {
                                                        product.quantity > 1 &&
                                                        <button
                                                            onClick={() => { decrease(product); refreshPage(); }}
                                                            className="btn btn-sm p-0 p-sm-1">
                                                            <i className="fa fa-minus-square fa-2x" aria-hidden="true"></i>
                                                        </button>
                                                    }
                                                    {
                                                        product.quantity === 1 &&
                                                        <button
                                                            onClick={() => { removeProduct(product); refreshPage(); }}
                                                            className="btn btn-md p-0 p-sm-1">
                                                            {/* <TrashIcon width={"20px"} /> */}
                                                            <i className="fa fa-trash fa-2x" aria-hidden="true"></i>
                                                        </button>
                                                    }

                                                    &nbsp; <b>{product.quantity}</b> &nbsp;
                                                    <button
                                                        onClick={() => { increase(product); refreshPage(); }}
                                                        className="btn btn-sm p-0 p-sm-1">
                                                        <i className="fa fa-plus-square fa-2x" aria-hidden="true"></i>
                                                    </button>

                                                </Col>
                                                {/* <for desktop /> */}
                                                <Col className="d-none d-sm-inline col-sm-3 col-6">
                                                    {
                                                        product.quantity > 1 &&
                                                        <button
                                                            onClick={() => { decrease(product); refreshPage(); }}
                                                            className="btn btn-sm p-0 p-sm-1">
                                                            <i className="fa fa-minus-square fa-2x" aria-hidden="true"></i>
                                                        </button>
                                                    }
                                                    {
                                                        product.quantity === 1 &&
                                                        <button
                                                            onClick={() => { removeProduct(product); refreshPage(); }}
                                                            className="btn btn-sm p-0 p-sm-1">
                                                            {/* <TrashIcon width={"20px"} /> */}
                                                            <i className="fa fa-trash fa-2x" aria-hidden="true"></i>
                                                        </button>
                                                    }
                                                    &nbsp; <b>{product.quantity}</b> &nbsp;
                                                    <button
                                                        onClick={() => { increase(product); refreshPage(); }}
                                                        className="btn btn-sm p-0 p-sm-1">
                                                        <i className="fa fa-plus-square fa-2x" aria-hidden="true"></i>
                                                    </button>

                                                </Col>
                                                <Col className="d-none d-sm-inline col-sm-2">
                                                    {currency.symbol} {currency.code === "JPY" ? Number(product.price * currency.factor).toFixed(0) : Number(product.price * currency.factor).toFixed(2)}
                                                </Col>
                                                <Col className="col-sm-2 col-4 d-inline d-flex justify-content-end">
                                                    <strong>{currency.symbol} {currency.code === "JPY" ? Number(product.price * currency.factor * product.quantity).toFixed(0) : Number(product.price * currency.factor * product.quantity).toFixed(2)}</strong>
                                                </Col>
                                                <Col className="d-none d-sm-inline col-1 pt-0">
                                                    <button
                                                        onClick={() => { removeProduct(product); refreshPage(); }}
                                                        className="btn btn-sm">
                                                        <i className="fa fa-window-close fa-2x" aria-hidden="true"></i>
                                                    </button>
                                                </Col>
                                            </Row>

                                        </React.Fragment>
                                    )
                                    )
                                }
                                <hr />
                                <Row>
                                    <Col className="col-sm-4 col-12">
                                        <Row className="mx-auto">
                                            <a href="#" onClick={clearCart}>{t('clear_cart')}</a>
                                        </Row>
                                        <Row className="mx-auto">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder={t('enter_product_number')} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" style={{ border: '1px solid black' }} type="button"><i className="fa fa-check" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row><br />
                            </Col>
                            <Col className="col-sm-3 col-12">
                                <OrderSummary orderDetails={orderDetails} />
                                <br />
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder={t('gift_card_or_disc_code')} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" style={{ border: '1px solid black' }} type="button"><i className="fa fa-check" aria-hidden="true"></i></button>
                                    </div>
                                </div><br />
                                <Link to="/checkout"><button className="btn-default btn-block text-uppercase">{t('proceed_to_checkout')}</button></Link><br />
                                <AmazonPayButton id="ap-button-cart" />
                                <br />
                                <CreateSBBuyer />
                            </Col>
                        </Row>
                    </React.Fragment> :
                    <div className="p-3 text-center text-muted">
                        {t('no_items_in_cart')}<br />
                        {t('add_items_to_cart')}
                    </div>
            }
        </Container>
    )

}

const Arrow = styled(LeftArrow)`
    color: black;
    height: 30px;
    width: 30px;
`;
const Anchor = styled(Link)`
    color: black;
    text-color: black;
    font-weight: bold;
`;
const Section = styled.section`
  color: black;

  /* Pass variables as inputs */
  padding: 1rem;
  /* Adjust the background from the properties */
  background: lightgray;
`;