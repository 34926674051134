import { useEffect, useState } from 'react'
import useFetch from './useFetch'
import useLocalStorage from './useLocalStorage'
import ApplicationConfig from '../types/ApplicationConfig'
import Environment from '../types/Environment'
import { getLocaleRegion } from 'src/helpers/customHelperFunctions'

export type Status = 'loading' | 'ready' | 'error'

function useConfig(): [Status, ApplicationConfig, string] {

    const locale = getLocaleRegion();
    const [region, setRegion] = useLocalStorage('region', locale);
    const [config, setConfig] = useLocalStorage('config', null as ApplicationConfig);
    const [status, setStatus] = useState<Status>('loading');

    const url = Environment.apiGatewayUrl + '/getApplicationConfig?region=' + region;
    const { fetchStatus, data, error } = useFetch<ApplicationConfig>(url);

    useEffect(
        () => {
            if (config == null || config.region !== region) {
                if (error) {
                    setStatus('error');
                    return;
                }
                // if successfully retrieved, cache in localStorage
                if (fetchStatus === 'fetched') {
                    setStatus('ready');
                    setConfig(data);
                }
            } else {
                // if already available in local storage, we're immediately ready
                setStatus('ready');
            }
        },
        [region, fetchStatus], // re-run effect when config has been fetched, or when region changed
    )

    return [status, config, error]
}

export default useConfig
