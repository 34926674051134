export class CheckoutSessionDetails implements ICheckoutSessionDetails {
    checkoutSessionId?: string
    recurringMetadata?: IRecurringMetadata;
    webCheckoutDetails?: IWebCheckoutDetails;
    product?: string
    paymentDetails?: IPaymentDetails;
    merchantMetadata?: IMerchantMetadata;
    platformId?: string;
    providerMetadata?: IProviderMetadata;
    buyer?: IBuyer;
    shippingAddress?: IAddress;
    billingAddress?: IAddress;
    paymentPreferences?: Array<IPaymentPreferences>;
    statusDetails?: IStatusDetails;
    constraints?: Array<IConstraint>
    creationTimestamp?: string;
    expirationTimestamp?: string;
    chargePermissionId?: string;
    chargeId?: string;
    storeId?: string;
    deliverySpecifications?: IDeliverySpecifications;
    releaseEnvironment?: string;
    supplementaryData?: string;
}

export interface ICheckoutSessionDetails {
    checkoutSessionId?: string;
    chargePermission?: string;
    recurringMetadata?: IRecurringMetadata;
    webCheckoutDetails?: IWebCheckoutDetails;
    product?: string
    paymentDetails?: IPaymentDetails;
    merchantMetadata?: IMerchantMetadata;
    platformId?: string;
    providerMetadata?: IProviderMetadata;
    buyer?: IBuyer;
    shippingAddress?: IAddress;
    billingAddress?: IAddress;
    paymentPreferences?: Array<IPaymentPreferences>;
    statusDetails?: IStatusDetails;
    constraints?: Array<IConstraint>
    creationTimestamp?: string;
    expirationTimestamp?: string;
    chargePermissionId?: string;
    chargeId?: string;
    storeId?: string;
    deliverySpecifications?: IDeliverySpecifications;
    releaseEnvironment?: string;
    supplementaryData?: string
}

export class ChargePermission implements IChargePermission {
    // TODO: add missing properties
    buyer?: IBuyer;
    shippingAddress?: IAddress;
    billingAddress?: IAddress;
    statusDetails?: IStatusDetails;
    chargePermissionId?: string;
}

export class BuyerDetail implements IBuyerDetail{
    buyerId: string;
    name?: string;
    email?: string;
    postalCode?: string;
    countryCode?: string;
    phoneNumber?: string;
    shippingAddress?: IAddress;
    billingAddress?: IAddress;
    primeMembershipTypes?: string;
}

export interface IChargePermission {
    buyer?: IBuyer;
    shippingAddress?: IAddress;
    billingAddress?: IAddress;
    statusDetails?: IStatusDetails;
    chargePermissionId?: string;
}

export interface IRecurringMetadata {
    frequency: IFrequency;
    amount: IPrice
}

export interface IFrequency {
    unit: string;
    value: string
}

export interface IPrice {
    amount: string;
    currencyCode: string
}

export interface IWebCheckoutDetails {
    checkoutReviewReturnUrl?: string;
    checkoutResultReturnUrl?: string;
    amazonPayRedirectUrl?: string;
    checkoutMode?: string
}

export interface IPaymentDetails {
    paymentIntent?: string;
    canHandlePendingAuthorization?: boolean;
    chargeAmount?: IPrice;
    totalOrderAmount?: IPrice;
    presentmentCurrency?: string;
    softDescriptor?: string;
    allowOvercharge?: boolean;
    extendExpiration?: boolean
}


export interface IMerchantMetadata {
    merchantReferenceId?: string;
    merchantStoreName?: string;
    noteToBuyer?: string;
    customInformation?: string
}

export interface IProviderMetadata {
    providerReferenceId: string
}

export interface IBuyer {
    buyerId: string;
    name?: string;
    email?: string
}

export interface IAddress {
    name?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    county?: string;
    district?: string;
    stateOrRegion?: string;
    postalCode?: string;
    countryCode?: string;
    phoneNumber?: string
}

export interface IPaymentPreferences {
    paymentDescriptor?: string
}

export interface IStatusDetails {
    state?: string;
    reasonCode?: string;
    reasonDescription?: string;
    lastUpdatedTimestamp?: string
}

export interface IConstraint {
    constraintId?: string;
    description?: string
}

export interface IDeliverySpecifications {
    specialRestrictions?: Array<string>;
    addressRestrictions?: IAddressRestrictions
}

export interface IAddressRestrictions {
    type?: string;
    restrictions?: string //hash<countryCode:restriction>
}

export interface IBuyerDetail {
    buyerId: string;
    name?: string;
    email?: string;
    postalCode?: string;
    countryCode?: string;
    phoneNumber?: string;
    shippingAddress?: IAddress;
    billingAddress?: IAddress;
    primeMembershipTypes?: string;
}