import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Currencies from "src/types/Currencies";
import ShippingCostSelector from "./ShippingCostSelector";
import styled from 'styled-components';

const OrderSummary = (props) => {
    const { t } = useTranslation();
    const currency = Currencies.getCurrentCurrency();
    const orderDetails = props.orderDetails;

    return (
        
        <Section >
            <h4>{t('summary')}</h4>
            <Row className="mt-2">
                <Col>{t('order_total')}</Col>
                <Col className="text-right">{currency.symbol} {currency.code === "JPY" ? Number(orderDetails.total * currency.factor).toFixed(0) : Number(orderDetails.total * currency.factor).toFixed(2)}</Col>
            </Row>
            <ShippingCostSelector shipping={orderDetails.shipping} cartItems={orderDetails.cartItems} />
            <Row className="mt-1">
                <Col>{t('net_total')}</Col>
                <Col className="text-right">{currency.symbol} {currency.code === "JPY" ? Number(orderDetails.netTotal * currency.factor).toFixed(0) : Number(orderDetails.netTotal * currency.factor).toFixed(2)}</Col>
            </Row>
            <Row className="mt-1">
                <Col>{t('plus_vat')}</Col>
                <Col className="text-right">{currency.symbol} {currency.code === "JPY" ? Number(orderDetails.vat * currency.factor).toFixed(0) : Number(orderDetails.vat * currency.factor).toFixed(2)}</Col>
            </Row>
            <Row className="mt-3">
                <Col className="body1">{t('grand_total')}</Col>
                <Col className="text-right body1">{currency.symbol} {currency.code === "JPY" ? (Number(orderDetails.finalTotal) * currency.factor).toFixed(0) : (Number(orderDetails.finalTotal) * currency.factor).toFixed(2)}</Col>
            </Row>
        </Section>
    );
}

const Section = styled.section`
  color: black;

  /* Pass variables as inputs */
  padding: 1rem;
  /* Adjust the background from the properties */
  border: 1px solid #dee2e6 !important;
  background-color: #f8f9fa !important;
`;

export default OrderSummary;