import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowIosBackOutline as LeftArrow } from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import { ArrowIosForwardOutline as RightArrow } from '@styled-icons/evaicons-outline/ArrowIosForwardOutline';
import { useTranslation } from 'react-i18next';
import useConfig from '../../custom-hooks/useConfig';
import ApiGateway from '../../helpers/ApiGateway';
import UrlHepler from '../../helpers/UrlHelper';
import { BuyerDetail, IBuyerDetail } from 'src/types/WebCheckoutTypes';
import pay from 'src/assets/images/paylogo.png';
import Address from '../../components/checkout/Address';
import LoginHelper from '../../helpers/LoginHelper';
import useAmazonPayScript from '../../custom-hooks/useAmazonPayScript';

declare const amazon: any

export default function Detail() {
    const { t } = useTranslation();
    const [configStatus, config] = useConfig();
    const [region, setRegion] = useState('');
    const [api] = useState(new ApiGateway());
    const [loginToken, setLoginToken] = useState('');        
    const [buyerData, setBuyerData] = useState(null as BuyerDetail);
    let token = UrlHepler.getUrlParam("buyerToken");
    const amazonPayScriptStatus = useAmazonPayScript();

    useEffect(
        () => {
            if (configStatus === 'ready') {
                api.setRegion(config.region);
                setRegion(config.region);
            }
        }, [configStatus]
    )

    useEffect(
        () => {
            if (configStatus === 'ready' && region != '' ) {
                // always prefer token from the URL            
                if (token !== null && token.length > 0){
                    setLoginToken(token);
                    LoginHelper.setToken(token);
                    let buyerDetail = getBuyerData(token);
                    setBuyerData(buyerDetail);                   
                } else {
                    // pull from storage if we can
                    const [isAuthenticated, login] = LoginHelper.loginIsValid;
                    if (isAuthenticated){
                        let buyerDetail = getBuyerData(login.token);
                        setBuyerData(buyerDetail);                  
                    } 
                    // todo: logout and redirect to login if no buyer found?
                }
            }
        }, [configStatus, token, region] // update also if region or token changes
    )

    function getBuyerData(token : string){        
        if (configStatus === 'ready' && buyerData == null) {
            api.getBuyer(token).then(details => {
                setBuyerData(details);                       
                console.log('getBuyer', token);
                return buyerData;
            }).catch(reason => {
                console.log(reason);
            });
        }           
        return null;         
    }

    function getDisplayName(buyerData : BuyerDetail){
        if (configStatus === 'ready' && buyerData?.name?.length > 0) {
            return `, ${buyerData.name}!`;            
        }
        return "";
    }

    function getLink(buyerToken : string){
        if (buyerToken !== ''){
            return `/account?buyerToken=${buyerToken};#`
        }
        return "#";
    }

    function doLogout(){
        LoginHelper.logout();
        try{
            /* attempt to signout from Amazon account, but don't error if unsuccessful */
            amazon.Pay.signout();
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Container className="main"><br />
            <Row>
                <Col className="col-3">
                    <Row className="justify-content-start offset-mt-2 mt-2">
                        <Col className="ml-0 pl-0"><Anchor to="/"><ArrowL /> {t('back_to_store')}</Anchor></Col>
                    </Row><br />
                    <Row>
                        <Col>
                            <Row>
                                <Col className="border-bottom font-weight-bold pb-2">{t('hi')}{getDisplayName(buyerData)}</Col>
                            </Row>
                            <Row>
                                <Col className="border-bottom font-weight-bold text-uppercase mt-2 pb-2">{t('account_overview')}</Col>
                            </Row>
                            <Row>
                                <Col className="border-bottom font-weight-bold text-uppercase mt-2 pb-2">{t('your_profile')}</Col>
                            </Row>
                            <Row>
                                <Col className="border-bottom font-weight-bold text-uppercase mt-2 pb-2">{t('addresses')}</Col>
                            </Row>
                            <Row>
                                <Col className="border-bottom font-weight-bold text-uppercase mt-2 pb-2">{t('payment_methods')}</Col>
                            </Row>
                            <Row>
                                <Col className="border-bottom font-weight-bold text-uppercase mt-2 pb-2">{t('orders')}</Col>
                            </Row>
                            <Row>
                                <Col className="border-bottom font-weight-bold text-uppercase mt-2 pb-2"><Anchor to="/login" onClick={() => doLogout()}>{t('sign_out')}</Anchor></Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-1"><Row> </Row></Col>
                <Col className="col-8 pl-20">
                    <Row>
                        <Col className="font-weight-bold text-uppercase mb-2 ml-0 pl-0">
                            <h3>{t('account_overview')}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col className="col-5 mt-4">
                                    <Row><Col className="font-weight-bold border-bottom text-uppercase pb-2 mb-2 ml-0 pl-0">{t('personal_details')}</Col></Row>
                                    <Row><Col className="ml-0 pl-0">{buyerData?.name}</Col></Row>
                                    <Row><Col className="ml-0 pl-0">{buyerData?.email}</Col></Row>
                                    <Row><Col className="ml-0 pl-0">{buyerData?.phoneNumber}</Col></Row>
                                    <Row><Col className="ml-0 pl-0"><Anchor to={getLink(loginToken)}>{t('edit_profile')}</Anchor><ArrowR/></Col></Row>
                                </Col>
                                <Col className="col-5 mt-4 ml-4< ">
                                    <Row >
                                        <Col className="font-weight-bold border-bottom text-uppercase pb-2 mb-2 ml-0 pl-0">{t('default_payment_method')}</Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-0 pl-0">Amazon Pay</Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-0 pl-0"><Image src={pay} height="20" /></Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-0 pl-0"><Anchor to={getLink(loginToken)}>{t('change_payment_method')}</Anchor><ArrowR/></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row><br/>
                    <Row>
                        <Col className="mb-2 ml-0 pl-0 font-weight-bold border-bottom text-uppercase">{t('newsletter_sub')}</Col>
                    </Row>
                    <Row>
                        <Col className="ml-0 pl-0"><input type="checkbox" style={{ border: 'red' }} /> {t('opt_in')}.</Col>
                    </Row><br/>
                    <Row>
                        <Col>
                            <Row>
                                <Col className="col-5 mt-4">
                                    <Row><Col className="font-weight-bold border-bottom text-uppercase pb-2 mb-2 ml-0 pl-0">{t('default_shipping_address')}</Col></Row>
                                    <Row>
                                        <Col className="ml-0 pl-0">
                                            <Address region={region} 
                                                name={buyerData?.name}
                                                addressLine1={buyerData?.shippingAddress?.addressLine1}
                                                addressLine2={buyerData?.shippingAddress?.addressLine2}
                                                addressLine3={buyerData?.shippingAddress?.addressLine3}
                                                city={buyerData?.shippingAddress?.city}
                                                stateOrRegion={buyerData?.shippingAddress?.stateOrRegion}
                                                countryCode={buyerData?.shippingAddress?.countryCode}
                                                postalCode={buyerData?.shippingAddress?.postalCode}/>        
                                                {buyerData?.shippingAddress?.phoneNumber}    
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-0 pl-0"><Anchor to={getLink(loginToken)}>{t('change_shipping_address')}</Anchor><ArrowR/></Col>
                                    </Row>
                                </Col>
                                <Col className="col-5 mt-4 ml-4 ">
                                    <Row >
                                        <Col className="font-weight-bold border-bottom text-uppercase pb-2 mb-2 ml-0 pl-0">{t('default_billing_address')}</Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-0 pl-0">
                                            <Address region={region} 
                                                name={buyerData?.name}
                                                addressLine1={buyerData?.billingAddress?.addressLine1}
                                                addressLine2={buyerData?.billingAddress?.addressLine2}
                                                addressLine3={buyerData?.billingAddress?.addressLine3}
                                                city={buyerData?.billingAddress?.city}
                                                stateOrRegion={buyerData?.billingAddress?.stateOrRegion}
                                                countryCode={buyerData?.billingAddress?.countryCode}
                                                postalCode={buyerData?.billingAddress?.postalCode}/>
                                                {buyerData?.billingAddress?.phoneNumber}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-0 pl-0"><Anchor to={getLink(loginToken)}>{t('change_billing_address')}</Anchor><ArrowR/></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row><br/>

                </Col>
            </Row>
        </Container>
    )   
}

const ArrowR = styled(RightArrow)`
    color: black;
    height: 30px;
    width: 30px;
`;
const ArrowL = styled(LeftArrow)`
    color: black;
    height: 30px;
    width: 30px;
`;
const Anchor = styled(Link)`
    color: black;
    text-color: black;
    font-weight: bold;
`;
const Section = styled.section`
  color: black;

  /* Pass variables as inputs */
  padding: 1rem;
  /* Adjust the background from the properties */
  background: lightgray;
`;
