import { useEffect, useState } from 'react';
import useConfig from '../custom-hooks/useConfig';

export interface ILogin {
    token: string;
    expiration: Date;
}

export default class LoginHelper{

    public static setToken(token : string){
        let tokenData = `${token}|${(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)).toUTCString()}`;
        localStorage.setItem("buyerToken", tokenData);
    };

    public static get loginIsValid(): [Boolean, ILogin]{
        let isValid : Boolean = false;
        let parsedToken : ILogin = null;
        try{
            let token = localStorage.getItem("buyerToken");
            parsedToken = LoginHelper.parseToken(token);
            if (parsedToken?.expiration !== null){
                if (parsedToken?.expiration > new Date()){
                    isValid = true;
                } else {
                    LoginHelper.logout();                    
                }
            }
        } catch (reason) {
            console.log(reason);
        }
        return [isValid, parsedToken];
    }

    public static logout() {
        localStorage.setItem("buyerToken", '');       
    };
    
    private static parseToken(token : string) : ILogin {
        if (token !== null && token.includes("|")){
            let data = token.split("|");
            let login : ILogin = {
                token : data[0],
                expiration : new Date(data[1])
            }
            return login;
        }
        return {
            token : null,
            expiration : null
        }        
    }  
     
}
