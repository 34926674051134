import React, { createContext, useState } from 'react';
import { dummyProducts } from '../types/dummyProducts';
import IProduct from './ProductType';

type Props = { children: React.ReactElement }

export const ProductsContext = createContext<IProduct[]>([]);

const ProductsContextProvider = ({ children }: Props) => {

    const [products] = useState(dummyProducts);

    return (
        <ProductsContext.Provider value={products} >
            { children}
        </ProductsContext.Provider>
    );
}

export default ProductsContextProvider;
