import React from 'react'
import { Image } from 'react-bootstrap';

interface Props {
    src: string;
}

class ProductImage extends React.Component<Props> {
    render() {
        return <Image className="img-fluid p-2 border" src={this.props.src}></Image>;
    }
}

export default ProductImage;