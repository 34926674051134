import React, { useContext, useEffect } from 'react'
import { Container, Row, Image, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Currencies from '../../types/Currencies';
import Product from '../../components/product/Product';
import banner from 'src/assets/images/home-banner.jpg';
import { useTranslation } from 'react-i18next';
import { ProductsContext } from '../../contexts/ProductsContext';
import styled from 'styled-components';
import { KeyboardArrowRight as RightArrow } from "@styled-icons/material-outlined/KeyboardArrowRight";
import { BASE_RETURN_URL } from 'src/helpers/customHelperFunctions';
import UrlHepler from '../../helpers/UrlHelper';

export default function Home() {

    const { t } = useTranslation();

    const currency = Currencies.getCurrentCurrency();
    const products = useContext(ProductsContext);

    // Check if Home Page has CheckoutSessionID QueryParameter and remove it
    const cxSessionQueryParameter = UrlHepler.getUrlParam("amazonCheckoutSessionId") ?? '';
    const buyerTokenQueryParameter = UrlHepler.getUrlParam("buyerToken") ?? '';

    useEffect(
        () => {
            let cxSessionID = cxSessionQueryParameter;
            let buyerToken = buyerTokenQueryParameter
            if (cxSessionID != '' || buyerToken != '') {
                window.location.href = BASE_RETURN_URL;
            }
        }
    )

    return (

        <Container className="main" id="homepage_content">  {/* ensures to have enough space for the sticky nav bar */}

            <Row className="mt-n4">
                <Col className="d-lg-flex">
                    <Image className="img-fluid of" src={banner}></Image>
                    <div className="d-none d-lg-block position-absolute mt-5 ml-5">
                        <h1>{t('home_hero_banner_title')}</h1>
                        <p className="body1">{t('home_hero_banner_subtitle')}</p>
                    </div>
                    <div className="d-lg-none position-relative">
                        <h1>{t('home_hero_banner_title')}</h1>
                        <p className="body1">{t('home_hero_banner_subtitle')}</p>
                    </div>
                </Col>
            </Row>

            <Row className="pt-5 pb-3 align-items-end" >
                <Col><h1 className="mb-0">{t('home_featured_products')}</h1></Col>
                <Col className="d-none d-md-inline text-right"><div className="body1">{t('home_all_featured_products')} <Arrow /></div></Col>
            </Row>

            <Row id="productsRow">
                {
                    products.map(product => (
                        <Col id="productColumn" key={product.id} xs="3">
                            <span className="boxed-invisible"></span>
                            <div className="alerts-border-invisible" id="individualProduct"><Link to={'/product?id=' + product.id}><Product id={product.id} image={product.photo} name={t(product.translationCodeName)} price={product.price * currency.factor} currency={currency.symbol}></Product></Link></div>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    )

}

const Arrow = styled(RightArrow)`
    color: black;
    height: 30px;
    width: 30px;
`;