import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types';
import Currencies from '../../types/Currencies';
import Languages from '../../types/Languages';
import Language from '../../types/Language';
import Environment from '../../types/Environment';
import useAmazonPayScript from '../../custom-hooks/useAmazonPayScript';
import useConfig from '../../custom-hooks/useConfig';
import { ACCOUNT_PAGE_RETURN_URL } from 'src/helpers/customHelperFunctions';
import ApiGateway from 'src/helpers/ApiGateway';
export interface AmazonPayButtonProps {
  id: string
}

declare const amazon: any

export function APaySignInButton({ id }: AmazonPayButtonProps) {

  const [configStatus, config, configError] = useConfig();
  const amazonPayScriptStatus = useAmazonPayScript();
  const currency = Currencies.getCurrentCurrency();
  const language = Languages.getCurrentLanguage();

  useEffect(() => {
    // if config and AP checkout.js have loaded, and amazon namespace is available, render the button
    if (configStatus == 'ready' && amazonPayScriptStatus == 'ready') {
      renderSignInButton(id);
    }
  }, [amazonPayScriptStatus, configStatus])

  return (
    // ToDo: Add String Translation - Conitnue to Pay
    <React.Fragment>
      <div id={id} className="sign-in-button"></div>
    </React.Fragment>
  )

  async function getSignature(region: string, returnUrl: string) {
    let encodedReturnUrl = encodeURIComponent(returnUrl);
    const api = new ApiGateway();
    let url =  Environment.apiGatewayUrl + '/generateButtonSignature?region=' + region + '&returnUrl=' + encodedReturnUrl + '&checkoutFlow=SignIn';
    let signature = api.generateButtonSignature(url);
    return signature;
  }

  // based on the given region, pick the best suiteable CV2 checkout language
  // NOTE: CV2 supportes US English only for US region, and GB English for UK, which is accomodated here
  function pickCheckoutLanguage(region: string, language: Language): string {

    if (region == 'jp') return Languages.Japanese.fourLetterCode; // as ja-JP is the only supported language for JP
    if (region == 'us') return Languages.English.fourLetterCode; // as en-US is the only supported language for US

    if (region == 'uk' || region == 'eu') {
      if (language == Languages.English) return 'en-GB'; // en_US not available in the EU/UK, so reset to en_GB
    }
    return language.fourLetterCode; // otherwise, use the language that was already set
  }

  async function renderSignInButton(id: string) {
    // explicitly using https here as http isn't accepted by our checkout (change URL manually on Cloud Desktop if you're on HTTP)
    const returnUrl = ACCOUNT_PAGE_RETURN_URL;
    const signatureGeneration = JSON.parse(await getSignature(config.region, returnUrl));

    console.log("Payload - " + JSON.stringify(signatureGeneration.payload));

    amazon.Pay.renderButton('#' + id, {
      merchantId: config.merchantId,
      publicKeyId: config.publicKeyId,
      ledgerCurrency: currency.code,
      sandbox: true,
      checkoutLanguage: pickCheckoutLanguage(config.region, language).replace('-', '_'),
      productType: "SignIn",
      placement: "Home",
      buttonColor: "Gold",
      signInConfig: {
        payloadJSON: JSON.stringify(signatureGeneration.payload),
        signature: signatureGeneration.signature
      }
    })
  }
}

APaySignInButton.propTypes = {
  id: PropTypes.string
}

export default APaySignInButton;
