import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types';
import Currencies from '../../types/Currencies';
import Languages from '../../types/Languages';
import Language from '../../types/Language';
import Environment from '../../types/Environment';
import useAmazonPayScript from '../../custom-hooks/useAmazonPayScript';
import useConfig from '../../custom-hooks/useConfig';
import { CartContext } from '../../contexts/CartContext';
import { REVIEW_PAGE_RETURN_URL } from 'src/helpers/customHelperFunctions';
import ApiGateway from 'src/helpers/ApiGateway';

export interface AmazonPayButtonProps {
    id: string,
}

declare const amazon: any

var flow = "Physical";

export function AmazonPayButton({ id }: AmazonPayButtonProps) {

    const [configStatus, config, configError] = useConfig();
    const amazonPayScriptStatus = useAmazonPayScript();

    const { finalTotal, cartItems, removeProduct } = useContext(CartContext);

    const currency = Currencies.getCurrentCurrency();
    const language = Languages.getCurrentLanguage();
    const api = new ApiGateway();

    useEffect(() => {
        // if config and AP checkout.js have loaded, and amazon namespace is available, render the button
        if (configStatus == 'ready' && amazonPayScriptStatus == 'ready') {
            if (cartItems.length === 1 && cartItems[0]?.id === 4) {
                flow = "Digital";
                renderAmazonPayButton(id, "PayOnly");
            } else if (cartItems.length === 1 && cartItems[0]?.id === 3) {
                flow = "Recurring";
                renderAmazonPayRecurringButton(id);
            } else {
                flow = "Physical";
                renderAmazonPayButton(id, "PayAndShip");
            }
        }
    }, [amazonPayScriptStatus, configStatus]);

    return (
        <React.Fragment>
            <div id={id} className="amazon-pay-button"></div>
        </React.Fragment>
    )

    async function getSignature(region: string, returnUrl: string) {
        let encodedReturnUrl = encodeURIComponent(returnUrl);
        var url = Environment.apiGatewayUrl + '/generateButtonSignature?region=' + region + '&returnUrl=' + encodedReturnUrl;
        let signature = api.generateButtonSignature(url);
        return signature;
    }

    async function getSignatureForRecurring(region: string, returnUrl: string) {
        let encodedReturnUrl = encodeURIComponent(returnUrl);
        let url = Environment.apiGatewayUrl + '/generateButtonSignature?region=' + region + '&returnUrl=' + encodedReturnUrl +
            '&checkoutFlow=' + flow + '&amount=' + (currency.code === "JPY" ? String(finalTotal.toFixed(0)) : String(finalTotal.toFixed(2))) + '&currencyCode=' + currency.code +
            '&frequencyUnit=' + (window.sessionStorage.getItem("frequencyUnit") ? window.sessionStorage.getItem("frequencyUnit") : "Month") + '&frequencyValue=' + (window.sessionStorage.getItem("frequencyValue") ? window.sessionStorage.getItem("frequencyValue") : "1");
        let signature = api.generateButtonSignature(url);
        return signature;
    }

    // based on the given region, pick the best suiteable CV2 checkout language
    // NOTE: CV2 supportes US English only for US region, and GB English for UK, which is accomodated here
    function pickCheckoutLanguage(region: string, language: Language): string {
        if (region == 'jp') return Languages.Japanese.fourLetterCode; // as ja-JP is the only supported language for JP
        if (region == 'us') return Languages.English.fourLetterCode; // as en-US is the only supported language for US

        if (region == 'uk' || region == 'eu') {
            if (language == Languages.English) return 'en-GB'; // en_US not available in the EU/UK, so reset to en_GB
        }
        return language.fourLetterCode; // otherwise, use the language that was already set
    }

    // Render function, which will just display the Amazon Pay button
    async function renderAmazonPayRecurringButton(id) {
        const baseReturnUrl = REVIEW_PAGE_RETURN_URL;
        const returnUrl = baseReturnUrl + '?flow=Recurring';
        const signatureGeneration = JSON.parse(await getSignatureForRecurring(config.region, returnUrl));

        console.log("Payload - " + JSON.stringify(signatureGeneration.payload));

        amazon.Pay.renderButton('#' + id, {
            merchantId: config.merchantId,
            ledgerCurrency: currency.code,
            sandbox: true,
            checkoutLanguage: pickCheckoutLanguage(config.region, language).replace('-', '_'),
            productType: "PayAndShip",
            placement: "Product",
            buttonColor: "Gold",
            publicKeyId: config.publicKeyId,
            createCheckoutSessionConfig: {
                payloadJSON: JSON.stringify(signatureGeneration.payload),
                signature: signatureGeneration.signature,
                publicKeyId: config.publicKeyId
            }
        });
    }

    async function renderAmazonPayButton(id, productType) {
        const baseReturnUrl = REVIEW_PAGE_RETURN_URL;
        const returnUrl = productType === "Digital" ? baseReturnUrl + '?flow=Digital' : baseReturnUrl;
        const signatureGeneration = JSON.parse(await getSignature(config.region, returnUrl));

        console.log("Payload - " + JSON.stringify(signatureGeneration.payload));

        amazon.Pay.renderButton('#' + id, {
            merchantId: config.merchantId,
            ledgerCurrency: currency.code,
            sandbox: true,
            checkoutLanguage: pickCheckoutLanguage(config.region, language).replace('-', '_'),
            productType: productType,
            placement: "Product",
            buttonColor: "Gold",
            createCheckoutSessionConfig: {
                payloadJSON: JSON.stringify(signatureGeneration.payload),
                signature: signatureGeneration.signature,
                publicKeyId: config.publicKeyId
            }
        });
    }

}

AmazonPayButton.propTypes = {
    id: PropTypes.string
}

export default AmazonPayButton;
