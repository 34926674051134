import React from 'react'
import ProductImage from '../product/ProductImage';
import { useTranslation } from 'react-i18next';

interface Props {
    id: number;
    image: string;
    name: string;
    price: number;
    currency: string;
}

function Product(props: Props) {
    const { t } = useTranslation();

    return (
        <div>
            <ProductImage src={props.image}></ProductImage>
            {/* To-Do: String translation */}
            {
                props.id === 3 &&
                <p className="mt-4"><strong>{t(props.name)}</strong><br />{t('starting_just')} {props.currency}{props.currency === "¥" ? Number(props.price).toFixed(0) : Number(props.price).toFixed(2)} {t('per_month')}</p>
            }
            {
                props.id != 3 &&
                <p className="mt-4"><strong>{t(props.name)}</strong><br />{props.currency}&nbsp;{props.currency === "¥" ? Number(props.price).toFixed(0) : Number(props.price).toFixed(2)}</p>
            }
        </div>
    );
}

export default Product;