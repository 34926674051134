import React, { createContext, useReducer } from 'react';
import { CartReducer, sumItems } from './CartReducer';
import IProduct from './ProductType';

type Props = { children: React.ReactElement }

type ICart = {
     itemCount: number,
     removeProduct: (payload: IProduct) => void,
     addProduct: (payload: IProduct) => void,
     increase: (payload: IProduct) => void,
     decrease: (payload: IProduct) => void,
     clearCart: () => void,
     handleCheckout: () => void,
     cartItems: Array<IProduct>,
     total: number,
     shipping: number,
     netTotal: number,
     vat: number,
     finalTotal: number,
     checkout: (payload: IProduct) => void,
}
const contextDefalutValues: ICart = {
     itemCount: 0,
     removeProduct: () => { },
     addProduct: () => { },
     increase: () => { },
     decrease: () => { },
     clearCart: () => { },
     handleCheckout: () => { },
     cartItems: [],
     total: 0,
     shipping: 0,
     netTotal: 0,
     vat: 0,
     finalTotal: 0,
     checkout: () => { }
}
export const CartContext = createContext<ICart>(contextDefalutValues);

const storage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart') ?? "[1]") : [];
const initialState = { cartItems: storage, ...sumItems(storage), checkout: false };

const CartContextProvider = ({ children }: Props) => {
     const [state, dispatch] = useReducer(CartReducer, initialState)
     const increase = (payload: IProduct) => {
          dispatch({ type: 'INCREASE', payload })
     }
     const decrease = (payload: IProduct) => {
          dispatch({ type: 'DECREASE', payload })
     }
     const addProduct = (payload: IProduct) => {
          dispatch({ type: 'ADD_ITEM', payload })
     }
     const removeProduct = (payload: IProduct) => {
          dispatch({ type: 'REMOVE_ITEM', payload })
     }
     const clearCart = () => {
          dispatch({ type: 'CLEAR' })
     }
     const handleCheckout = () => {
          console.log('CHECKOUT', state);
          dispatch({ type: 'CHECKOUT' })
     }
     const contextValues = {
          removeProduct,
          addProduct,
          increase,
          decrease,
          clearCart,
          handleCheckout,
          ...state
     }
     return (
          <CartContext.Provider value={contextValues} >
               { children}
          </CartContext.Provider>
     );
}
export default CartContextProvider;