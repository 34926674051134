import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../../pages/home';
import Cart from '../../pages/cart';
import Product from '../../pages/product';
import Checkout from '../../pages/checkout';
import Error404 from '../../pages/Error';
import Review from '../../pages/checkout/review';
import Confirm from '../../pages/checkout/confirm';
import Account from '../../pages/account/detail';
import LogIn from '../../pages/account/index';

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/demosite" component={Home} />
            <Route exact path="/product/:id?" component={Product} />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/review" component={Review} />
            <Route exact path="/confirm" component={Confirm} />
            <Route exact path="/login" component={LogIn} />
            <Route exact path="/account" component={Account} />
            <Route exact path="*" component={Error404} />
        </Switch>
    );
};

export default Routes;