import React from 'react'
import { Container, Row, Image, Col } from 'react-bootstrap';
import kitchenSmartLogo from 'src/assets/vectors/kitchensmartlogo.svg';
import amazonPayLogo from 'src/assets/vectors/logo_amazonpay-primary-fullcolor-positive.svg';
import accMark1 from 'src/assets/vectors/acceptancemark-paymentmethod-1.svg';
import accMark2 from 'src/assets/vectors/acceptancemark-paymentmethod-2.svg';
import accMark3 from 'src/assets/vectors/acceptancemark-paymentmethod-3.svg';
import accMarkAmazonPay from 'src/assets/vectors/acceptancemark-paymentmethod-4-amazonpay.svg';
import { useTranslation } from 'react-i18next';
import FooterColumn from './FooterColumn';
import Languages from 'src/types/Languages';
import { getAnchorLinkPrivacyPolicy } from 'src/helpers/customHelperFunctions';

export function Footer() {

    const { t } = useTranslation();
    const columnProperties = [{
        category: 'shop',
        list: ['cookware', 'bakeware', 'new_arrivals', 'groceries', 'bulk_ing', 'gift_cards']
    },
    {
        category: 'explore',
        list: ['aboutKS', 'my_account', 'advanced_search', 'site_map']
    },
    {
        category: 'support',
        list: ['customer_service', 'faq', 'return_policy', 'privacy_policy', 'terms_of_sale', 'returns', 'legal', 'report_bug']
    },
    {
        category: 'connect',
        list: ['facebook', 'instagram', 'linkedin', 'twitter', 'youtube'],
        link: [ 'https://www.facebook.com/AmazonPay/?ld=APNALPADirect',
                'https://www.instagram.com/amazonpay/?ld=APNALPADirect',
                'https://www.linkedin.com/showcase/amazon-pay/?ld=APNALPADirect',
                'https://twitter.com/amazonpay?ld=APNALPADirect',
                'https://www.youtube.com/user/amazonpayments?ld=APNALPADirect'
            ]
    }
    ]
   

    return (

        <footer className="text-lg-start mt-5 mb-5 pt-5">

            <Container>

                <Row className="row">

                    <Col lg={4} id="logo-container">
                        <Image src={kitchenSmartLogo} width="230"></Image>
                        <div className="mt-3"><Image src={amazonPayLogo} width="150"></Image></div>
                    </Col>

                    {columnProperties.map((props) => {
                        return <FooterColumn key={props.list.toString()} props={props} />
                    })}


                    <Col lg={4} id="acceptance-marks-container">
                        <div className="mt-5 body7"><a href={getAnchorLinkPrivacyPolicy(Languages.getCurrentLanguage()?.twoLetterCode)} target="_blank" className="text-dark">{t('copyright')}</a></div>
                        <div className="mt-3 mb-4">
                            <Image src={accMark1}></Image>
                            <Image className="ml-2" src={accMark2}></Image>
                            <Image className="ml-2" src={accMark3}></Image>
                            <Image className="ml-2" src={accMarkAmazonPay}></Image>
                        </div>
                    </Col>


                </Row>

            </Container>

        </footer>

    )

}

export default Footer;