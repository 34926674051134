import React, { useContext } from "react";
import {
    Nav,
    Navbar,
    Form,
    Image,
    Container,
    InputGroup,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import logo from "src/assets/vectors/kitchensmartlogo.svg";
import accountIcon from "src/assets/vectors/profileicon.svg";
import cartIcon from "src/assets/vectors/carticon-empty.svg";
import searchIcon from "src/assets/vectors/searchicon.svg";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CartContext } from "../../contexts/CartContext";
import LoginHelper from '../../helpers/LoginHelper';

const NavigationBar = () => {
    const { t } = useTranslation();
    const { itemCount } = useContext(CartContext);

    function getAccountLink() {
        const [isAuthenticated, login] = LoginHelper.loginIsValid;
        if (isAuthenticated) {
            if (login !== null && login.token?.length > 0) {
                return `/account?buyerToken=${login.token}`;
            }

        }
        return "/login";
    }

    const history = useHistory();

    return (
        <>
            <Navbar
                variant="light"
                fixed="top"
                expand="lg"
                className="pt-4 pb-4 navbar-bottom"
            >
                <Container>
                    <Navbar.Brand className="d-none d-lg-block">
                        <Link to="/" onClick={() => history.push('/')}>
                            <Image src={logo} width="200"></Image>
                        </Link>
                    </Navbar.Brand>

                    {/* some "mobile only" handling (burger menu, centered logo, search icon on the left, etc.) */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-1" />
                    <Nav.Link as={Link} to="/" className="mr-auto d-lg-none ml-sm-4">
                        <Image src={searchIcon} className="nav-icon"></Image>
                    </Nav.Link>
                    <Navbar.Brand className="mr-auto d-lg-none">
                        <Link to="/" onClick={() => history.push('/')}>
                            <Image src={logo} width="160"></Image>
                        </Link>
                    </Navbar.Brand>
                    {/* Aligning the cart and account icon for mobile view to support colapsible navbar*/}
                    <Nav.Link as={Link} to={getAccountLink()} className="d-lg-none">
                        <Image src={accountIcon} className="nav-icon"></Image>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/cart" className="pr-0 d-lg-none">
                        {itemCount != 0 && (
                            <div style={{ position: "relative", width: "fit-content" }}>
                                <Image src={cartIcon} className="nav-icon"></Image>
                                <Count>{itemCount}</Count>
                            </div>
                        )}
                        {itemCount == 0 && (
                            <div style={{ position: "relative", width: "fit-content" }}>
                                <Image src={cartIcon} className="nav-icon"></Image>
                            </div>
                        )}
                    </Nav.Link>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link as={Link} to="/" onClick={() => history.push('/')} className="nav-2 ml-xl-4">
                                {t("home")}
                            </Nav.Link>
                            <Nav.Link className="nav-2 ml-xl-4">{t("shop")}</Nav.Link>
                            <Nav.Link className="nav-2 ml-xl-4">{t("sale")}</Nav.Link>
                        </Nav>
                        <Form inline className="d-none d-lg-block mr-4 search-control">
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder={t("search")}
                                    aria-describedby="inputGroupPrepend"
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <Image src={searchIcon} id="inner-search-icon"></Image>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Form>
                    </Navbar.Collapse>

                    <Nav.Link as={Link} to={getAccountLink()} className="d-lg-block d-none">
                        <Image src={accountIcon} className="nav-icon"></Image>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/cart" className="d-lg-block d-none pr-0">
                        {itemCount != 0 && (
                            <div style={{ position: "relative", width: "fit-content" }}>
                                <Image src={cartIcon} className="nav-icon"></Image>
                                <Count>{itemCount}</Count>
                            </div>
                        )}
                        {itemCount == 0 && (
                            <div style={{ position: "relative", width: "fit-content" }}>
                                <Image src={cartIcon} className="nav-icon"></Image>
                            </div>
                        )}
                    </Nav.Link>
                </Container>
            </Navbar>
        </>
    );
};

export default NavigationBar;

const Count = styled.span`
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
  -moz-border-radius: 25px;
  border-radius: 25px;
  border: solid 1.5px black;
  background-color: lightgrey;
  color: black;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 1.1em;
`;
