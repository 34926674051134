import React from 'react'
import PostalAddress from 'i18n-postal-address'
import DOMPurify from "dompurify"

interface Props {
    region : string;
    name : string;
    addressLine1 ?: string;
    addressLine2 ?: string;
    addressLine3 ?: string;
    city ?: string;
    county ?: string;
    district ?: string;
    stateOrRegion ?: string;
    postalCode ?: string;
    countryCode ?: string;
    phoneNumber ?: string;
}

function Address(props: Props)  {   
    function displayAddressLines(props: Props){
        const displayAddress = new PostalAddress();
        const region = props.region.toLowerCase();

        if (props.addressLine1?.length > 0)
            displayAddress.setAddress1(props.addressLine1);
        
        if (props.addressLine2?.length > 0)
            displayAddress.setAddress2(props.addressLine2);

        if (props.city?.length > 0)
            displayAddress.setCity(props.city);

        if (props.stateOrRegion?.length > 0){
            if (region == 'jp')
                displayAddress.setPrefecture(props.stateOrRegion);

            if (region == 'uk' || region == 'eu')
                displayAddress.setRegion(props.stateOrRegion);

            if (region == 'us')
                displayAddress.setState(props.stateOrRegion);
        }

        if (props.countryCode?.length > 0)
            displayAddress.setCountry(props.countryCode);

        if (props.countryCode?.length > 0){
            displayAddress
                .setPostalCode(props.postalCode)        
                .setFormat({
                    country: props.countryCode,
                    type: 'personal',
                });      
        }
        else {
            displayAddress.setFormat({                
                type: 'personal',
            }); 
        }

        displayAddress
        .setFirstName(props.name)
        .setOutputFormat('array');        
        return displayAddress.toString().replace(/(?:\r\n|\r|\n)/g, "<br/>");
    }
    
    return (
        <div dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(displayAddressLines(props))}}></div>
    );    
}

export default Address;