import Language from './Language';
import { getDefaultLocale } from 'src/helpers/customHelperFunctions';

class Languages {

  // make the known languages available as properties for easier access from code
  public static English: Language;
  public static German: Language;
  public static French: Language;
  public static Italian: Language;
  public static Spanish: Language;
  public static Japanese: Language;

  private static languages: Array<Language>;

  public static initialize() {
    this.languages = new Array<Language>();

    Languages.English = new Language("en", "en-US");
    this.languages.push(Languages.English);

    Languages.German = new Language("de", "de-DE");
    this.languages.push(Languages.German);

    Languages.French = new Language("fr", "fr-FR");
    this.languages.push(Languages.French);

    Languages.Italian = new Language("it", "it-IT");
    this.languages.push(Languages.Italian);

    Languages.Spanish = new Language("es", "es-ES");
    this.languages.push(Languages.Spanish);

    Languages.Japanese = new Language("jp", "ja-JP");
    this.languages.push(Languages.Japanese);
  }

  public static getLanguageFromLocale() {
    const locale = getDefaultLocale();
    switch (locale) {
      case "fr": return Languages.French.fourLetterCode;
      case "it": return Languages.Italian.fourLetterCode;
      case "es": return Languages.Spanish.fourLetterCode;
      case "de": return Languages.German.fourLetterCode;
      case "jp": return Languages.Japanese.fourLetterCode;
      default: return Languages.English.fourLetterCode;
    }
  }

  public static getLanguageFromLanguageCode(languageCode: string): Language {
    languageCode = languageCode.toLowerCase();
    if (languageCode.length == 2) {
      return Languages.languages.find((e) => e.twoLetterCode.toLowerCase() === languageCode)!;
    }
    return Languages.languages.find((e) => e.fourLetterCode.toLowerCase() === languageCode)!;
  }

  public static getCurrentLanguage(): Language {
    let storedLanguageCode = localStorage.getItem('i18nextLng') || this.getLanguageFromLocale();
    let language = Languages.getLanguageFromLanguageCode(storedLanguageCode);
    localStorage.setItem('i18nextLng', language.fourLetterCode);
    return language;
  }
}

Languages.initialize();
export default Languages;