import { useEffect, useReducer, useRef } from 'react'

import ApiGateway, { IApiError } from '../helpers/ApiGateway';

// State & hook output
interface State<T> {
  fetchStatus: 'init' | 'fetching' | 'error' | 'fetched'
  data?: T
  error?: string
}

interface Cache<T> {
  [url: string]: T
}

// discriminated union type
type Action<T> =
  | { type: 'request' }
  | { type: 'success'; payload: T }
  | { type: 'failure'; payload: string }

function useFetch<T = unknown>(
  url?: string,
): State<T> {
  const cache = useRef<Cache<T>>({})
  const cancelRequest = useRef<boolean>(false)

  const initialState: State<T> = {
    fetchStatus: 'init',
    error: undefined,
    data: undefined,
  }

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'request':
        return { ...initialState, fetchStatus: 'fetching' }
      case 'success':
        return { ...initialState, fetchStatus: 'fetched', data: action.payload }
      case 'failure':
        return { ...initialState, fetchStatus: 'error', error: action.payload }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  useEffect(() => {
    if (!url) {
      return
    }

    const fetchData = async () => {
      dispatch({ type: 'request' })

      if (cache.current[url]) {
        dispatch({ type: 'success', payload: cache.current[url] })
      } else {
        try {
          const api = new ApiGateway();
          const response = await api.getApplicationConfig(url)
          cache.current[url] = response.data

          if (cancelRequest.current) return

          dispatch({ type: 'success', payload: response.data })
        } catch (error) {
          if (cancelRequest.current) return

          dispatch({ type: 'failure', payload: error.message })
        }
      }
    }

    fetchData()

    return () => {
      cancelRequest.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return state
}

export default useFetch
