import React, { useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import categorybreadcrumbicon from 'src/assets/vectors/categorybreadcrumbicon.svg';
import productrating from 'src/assets/vectors/productrating-complete.svg';
import styled from 'styled-components';
import SlidingPane from 'react-sliding-pane';
require('react-sliding-pane/dist/react-sliding-pane.css');
import { Row, Col, Container, Image } from 'react-bootstrap';
import Currencies from '../../types/Currencies';
import PaneDetails from './PaneDetails';
import AmazonPayCustomButton from '../../components/amazon-pay-button/AmazonPayCustomButton';
import { ArrowIosForwardOutline as RightArrow } from '@styled-icons/evaicons-outline/ArrowIosForwardOutline';
import { useTranslation } from 'react-i18next';
import { ProductsContext } from '../../contexts/ProductsContext';
import { CartContext } from '../../contexts/CartContext';
import { refreshPage } from 'src/helpers/customHelperFunctions';
import CreateSBBuyer from 'src/helpers/CreateSBBuyer';

export function Product() {

     // Default value set for Recurring frequency
     const [select, setSelect] = useState(window.sessionStorage.getItem("frequencyUnit") ? window.sessionStorage.getItem("frequencyUnit") + window.sessionStorage.getItem("frequencyValue") : "Week1");
     const handleSelectChange = event => {
          const value = event.target.value;
          setSelect(value);
     };

     var frequencyValue = select === "Week2" || select === "Month2" ? 2 : 1;
     window.sessionStorage.setItem("frequencyValue", frequencyValue.toString());
     var frequencyUnit = select.slice(0, -1);
     window.sessionStorage.setItem("frequencyUnit", frequencyUnit.toString());

     const { t } = useTranslation();
     const [quantityDifferenceOnSwitcher, setQuantityDifferenceOnSwitcher] = useState(0);
     const [isPaneOpen, setIsPaneOpen] = useState(false);
     const currency = Currencies.getCurrentCurrency();

     const id = new URLSearchParams(useLocation().search).get("id") ?? 0;
     const productID: number = + id;
     const product = useContext(ProductsContext)!.find(obj => obj.id == productID)!;

     const { addProduct, cartItems, increase } = useContext(CartContext);
     const isInCart = (product: any) => {
          return !!cartItems.find(item => item.id === product.id);
     }

     window.onload = function () {
          var reloading = sessionStorage.getItem("reloading");
          if (reloading) {
               sessionStorage.removeItem("reloading");
               setIsPaneOpen(true);
          }
     }

     function reloadPage() {
          sessionStorage.setItem("reloading", "true");
          document.location.reload();
     }

     return (
          <Container className="main">

               <Row className="mx-auto">
                    <Col>
                         <Image className="img-fluid" src={categorybreadcrumbicon} />
                         &ensp; <span className="d-none d-sm-inline">{t('shop')} <Arrow /> {t('kitchenware')} <Arrow /> </span>{t(product?.type)}
                    </Col>
               </Row>

               <Row className="mt-4 pb-4">
                    <Col sm={6}>
                         <Image className="img-fluid product-detail" src={product?.photo} />
                    </Col>
                    <Col sm={6}>
                         <h2>{t(product?.translationCodeName)}</h2>
                         {
                              product?.id === 3 &&
                              <h2 className="text-title text-uppercase text-muted mt-3 mb-2">{currency.symbol} {currency.code === "JPY" ? Number((product?.price) * currency.factor).toFixed(0) : Number((product?.price) * currency.factor).toFixed(2)} </h2>
                         }
                         {
                              product?.id != 3 &&
                              <h2 className="text-title text-uppercase text-muted mt-3 mb-2">{currency.symbol} {currency.code === "JPY" ? Number((product?.price) * currency.factor).toFixed(0) : Number((product?.price) * currency.factor).toFixed(2)}</h2>
                         }
                         <strong><hr /></strong>
                         {t('quantity')}:  &nbsp; 
                         { 
                              isInCart(product) && 
                              cartItems.filter(product => product.id === productID).map(product => (
                                   <input defaultValue={product.quantity} type="number" min="1" max="10" onChange={event => setQuantityDifferenceOnSwitcher(parseInt(event.target.value)-product.quantity)} />           
                              )
                              )
                         }
                         {
                              !isInCart(product) && 
                                   <input defaultValue="1" type="number" min="1" max="10" onChange={event => setQuantityDifferenceOnSwitcher(parseInt(event.target.value))} />
                         } 
                                                                                       
                         <hr />
                         {
                              product?.id === 3 &&
                              <React.Fragment>
                                   {/* To-Do: Add string translations */}
                                   {t('recurring_frequency')}
                                   <br />
                                   <Wrapper>
                                        <Item>
                                             <RadioButton
                                                  type="radio"
                                                  name="radio"
                                                  value="Week1"
                                                  checked={select === "Week1"}
                                                  onChange={event => { handleSelectChange(event); refreshPage(); }}
                                             />
                                             <RadioButtonLabel />
                                             <div>{t('once_a_week')}</div>
                                        </Item>
                                        <Item>
                                             <RadioButton
                                                  type="radio"
                                                  name="radio"
                                                  value="Week2"
                                                  checked={select === "Week2"}
                                                  onChange={event => { handleSelectChange(event); refreshPage(); }}
                                             />
                                             <RadioButtonLabel />
                                             <div>{t('other_week')}</div>
                                        </Item>
                                        <Item>
                                             <RadioButton
                                                  type="radio"
                                                  name="radio"
                                                  value="Month1"
                                                  checked={select === "Month1"}
                                                  onChange={event => { handleSelectChange(event); refreshPage(); }}
                                             />
                                             <RadioButtonLabel />
                                             <div>{t('once_a_month')}</div>
                                        </Item>
                                        <Item>
                                             <RadioButton
                                                  type="radio"
                                                  name="radio"
                                                  value="Month2"
                                                  checked={select === "Month2"}
                                                  onChange={event => { handleSelectChange(event); refreshPage(); }}
                                             />
                                             <RadioButtonLabel />
                                             <div>{t('other_month')}</div>
                                        </Item>
                                   </Wrapper>
                                   <hr />
                              </React.Fragment>
                         }
                         <Row className="mt-3">
                              {/* ToDo: Add String Translation - For Add More Button */}
                              <Col lg={6}>
                                   {
                                        isInCart(product) &&
                                        <button
                                             onClick={() => {
                                                  for(let i = 0; i < quantityDifferenceOnSwitcher; i++) {
                                                       increase(product);
                                                  } 
                                                  if(quantityDifferenceOnSwitcher === 0) {
                                                       increase(product);
                                                  }
                                                  reloadPage();
                                             }}
                                             className="btn-default btn-block text-uppercase">{t('add_more')}</button>
                                   }
                                   {
                                        !isInCart(product) &&
                                        <button
                                             onClick={() => {
                                                  addProduct(product);
                                                  for (let i = 0; i < quantityDifferenceOnSwitcher-1; i++) {
                                                      increase(product);
                                                  }
                                                  reloadPage();
                                             }}
                                             className="btn-default btn-block text-uppercase">{t('add_to_cart')}</button>
                                   }
                                   <Pane
                                        overlayClassName='main'
                                        isOpen={isPaneOpen}
                                        title={t('continue_shopping')}
                                        width='500px'
                                        onRequestClose={() => { setIsPaneOpen(false); }}>
                                        <PaneDetails myProps={product} frequencyUnit={frequencyUnit} frequencyValue={frequencyValue} />
                                   </Pane>
                              </Col>
                         </Row>
                         <Row className="mt-3 mb-5">
                              <Col lg={6}>
                                   <AmazonPayCustomButton id="ap-button-product" productID={productID} />
                                   <br/>
                                   <CreateSBBuyer/>
                              </Col>
                         </Row>
                    </Col>
               </Row>
               <Row>
                    <Col lg={6} className="mb-4">
                         <h5 className="cta-1">{t('product_description')}</h5>
                         <hr />
                         <p>{t('lorem_ipsum')}</p>
                         <div className="font-weight-bold">{t('see_more_details')} <Arrow /></div>
                    </Col>
                    <Col lg={6}>
                         <h5 className="cta-1">{t('product_reviews')}</h5>
                         <hr />
                         <Image src={productrating} className="mb-2 mr-2" />4.8/5 {t('stars')}<br />
                         <div className="font-weight-bold">{t('see_reviews')} <Arrow /></div>
                    </Col>
               </Row>
          </Container>
     )
}

const Arrow = styled(RightArrow)`
    color: black;
    height: 30px;
    width: 30px;
`;

const Anchor = styled(Link)`
    color: black;
    text-color: black;
    font-weight: bold;
`;

const Pane = styled(SlidingPane)`
    zIndex: "10";
    position: "relative";
`;

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0px 16px 0px 10px;
  box-sizing: border-box;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 50%;
  position: relative;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #ccc;
    &::after {
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${Item} {
    background: gray;
    border: 2px solid gray;
  }
  &:checked + ${RadioButtonLabel} {
    background: gray;
    border: 1px solid gray;
    &::after {
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
`;

export default Product;