import React from 'react';
import { ChargePermission } from 'src/types/WebCheckoutTypes';
import Address from './Address';

export const BillingAddressRetrieveFromCheckoutSession = ({ checkoutSessionData, region }) => {
  return (
    <Address region={region}
      name={checkoutSessionData?.billingAddress?.name}
      addressLine1={checkoutSessionData?.billingAddress?.addressLine1}
      addressLine2={checkoutSessionData?.billingAddress?.addressLine2}
      addressLine3={checkoutSessionData?.billingAddress?.addressLine3}
      city={checkoutSessionData?.billingAddress?.city}
      stateOrRegion={checkoutSessionData?.billingAddress?.stateOrRegion}
      countryCode={checkoutSessionData?.billingAddress?.countryCode}
      postalCode={checkoutSessionData?.billingAddress?.postalCode}
      phoneNumber={checkoutSessionData?.billingAddress?.phoneNumber} />
  )
}

export const ShippingAddressRetrieveFromCheckoutSession = ({ checkoutSessionData, region }) => {
  return (
    <Address region={region} name={checkoutSessionData?.shippingAddress?.name}
      addressLine1={checkoutSessionData?.shippingAddress?.addressLine1}
      addressLine2={checkoutSessionData?.shippingAddress?.addressLine2}
      addressLine3={checkoutSessionData?.shippingAddress?.addressLine3}
      city={checkoutSessionData?.shippingAddress?.city}
      stateOrRegion={checkoutSessionData?.shippingAddress?.stateOrRegion}
      countryCode={checkoutSessionData?.shippingAddress?.countryCode}
      postalCode={checkoutSessionData?.shippingAddress?.postalCode}
      phoneNumber={checkoutSessionData?.shippingAddress?.phoneNumber} />
  )
}

export const BillingAddressRetrieveFromChargePermission = ({ chargePermissionData, region }) => {
  return (
    <Address region={region}
      name={(chargePermissionData?.billingAddress?.name && chargePermissionData?.billingAddress?.name.length > 0) ? chargePermissionData?.billingAddress?.name : 'Test Checkout'}
      addressLine1={chargePermissionData?.billingAddress?.addressLine1}
      addressLine2={chargePermissionData?.billingAddress?.addressLine2}
      addressLine3={chargePermissionData?.billingAddress?.addressLine3}
      city={chargePermissionData?.billingAddress?.city}
      stateOrRegion={chargePermissionData?.billingAddress?.stateOrRegion}
      countryCode={chargePermissionData?.billingAddress?.countryCode}
      postalCode={chargePermissionData?.billingAddress?.postalCode}
      phoneNumber={chargePermissionData?.billingAddress?.phoneNumber} />
  );
}

export const ShippingAddressRetrieveFromChargePermission = ({ chargePermissionData, region }) => {
  return (
    <Address region={region}
      name={(chargePermissionData?.shippingAddress?.name && chargePermissionData?.shippingAddress?.name.length > 0) ? chargePermissionData?.shippingAddress?.name : 'Test Checkout'}
      addressLine1={chargePermissionData?.shippingAddress?.addressLine1}
      addressLine2={chargePermissionData?.shippingAddress?.addressLine2}
      addressLine3={chargePermissionData?.shippingAddress?.addressLine3}
      city={chargePermissionData?.shippingAddress?.city}
      stateOrRegion={chargePermissionData?.shippingAddress?.stateOrRegion}
      countryCode={chargePermissionData?.shippingAddress?.countryCode}
      postalCode={chargePermissionData?.shippingAddress?.postalCode}
      phoneNumber={chargePermissionData?.shippingAddress?.phoneNumber} />
  )
}