import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getDefaultLocale } from './customHelperFunctions';

export default function CreateSBBuyer() {

  const { t } = useTranslation();

  // This function returns appropriate tooltip link on the marketing site based on current locale
  const getMarketingAnchorLink = () => {
    const locale = getDefaultLocale();
    switch (locale) {
      case "en": return "https://pay.amazon.com/demo#test-account";
      case "de": return "https://pay.amazon.de/demo#test-account";
      case "fr": return "https://pay.amazon.fr/demo#test-account";
      case "it": return "https://pay.amazon.it/demo#test-account";
      case "es": return "https://pay.amazon.es/demo#test-account";
      case "eu": return "https://pay.amazon.eu/demo#test-account";
      case "uk": return "https://pay.amazon.co.uk/demo#test-account";
      case "jp": return "https://pay.amazon.co.jp/demo#test-account";
      default: return "https://pay.amazon.com/demo#test-account";
    }
  }

  return (
    <CreateSBBuyerAnchor href={getMarketingAnchorLink()} target="_blank">{t('create_sandbox_buyer')}</CreateSBBuyerAnchor>
  )
}

const CreateSBBuyerAnchor = styled.a`
    display: flex;
    border: 1px dotted black;
    color: black;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    border-bottom: 3px solid black;
    -webkit-transition: border 400ms ease;
    -moz-transition: border 400ms ease;
    -ms-transition: border 400ms ease;
    -o-transition: border 400ms ease;
    transition: border 400ms ease;
    &:hover {
        border-bottom: 3px solid black
    }
    &:active {
        border-bottom: 3px solid black;
    }
`