import React, { useContext } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AmazonPayButton from '../../components/amazon-pay-button/AmazonPayButton';
import Currencies from '../../types/Currencies';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../contexts/CartContext';
import CreateSBBuyer from 'src/helpers/CreateSBBuyer';

const PaneDetails = (props) => {

     const { t } = useTranslation();

     const product = props.myProps;
     const { total, shipping, cartItems, removeProduct } = useContext(CartContext);
     const currency = Currencies.getCurrentCurrency();
     const productID = product?.id;

     return (
          <div>
               <h2>{t('shopping_cart')}</h2><br />
               {
                    productID === 3 &&
                    <React.Fragment>
                         <WrapperAlert>
                              <TitleAlert>
                                   <span><i className="fa fa-exclamation-circle fa-2x"></i>  {t('recurring_checkout_prompt')}</span>
                              </TitleAlert>
                         </WrapperAlert>
                         <br />
                    </React.Fragment>
               }
               {
                    productID === 4 &&
                    <React.Fragment>
                         <WrapperAlert>
                              <TitleAlert>
                                   <span><i className="fa fa-exclamation-circle fa-2x"></i>  {t('digital_checkout_prompt')}</span>
                              </TitleAlert>
                         </WrapperAlert>
                         <br />
                    </React.Fragment>
               }
               <Wrapper>
                    <Title>
                         {t('one_item_added_to_cart')} <i className="fa fa-check pl-5 pt-3" aria-hidden="true"></i>
                    </Title>
               </Wrapper>
               <br />
               {
                    cartItems.length > 0 ?
                         <React.Fragment>
                              <Row>
                                   <Col className="col-12">
                                        <Row>
                                             <Col className="col-5 text-uppercase mx-auto">{t('product')}</Col>
                                             <Col className="col-3 text-uppercase mx-auto">&nbsp; {t('quantity')}</Col>
                                             <Col className="col-3 text-uppercase mx-auto">{t('item_price')}</Col>
                                        </Row>
                                        <hr />

                                        {
                                             cartItems.map(product => (
                                                  <React.Fragment key={product.id}>
                                                       <Row>
                                                            <Col className="col-2 mx-auto">
                                                                 <Image className="img-fluid" src={product.photo} width="90" height="90" />
                                                            </Col>
                                                            <Col className="col-3 mx-auto">
                                                                 <b>{t(product?.translationCodeName)}</b>
                                                            </Col>
                                                            <Col className="col-3 mx-auto">
                                                                 <b>{product.quantity}</b>
                                                            </Col>
                                                            <Col className="col-3 mx-auto">
                                                                 {currency.symbol} {currency.code === "JPY" ? Number(product.price * currency.factor).toFixed(0) : Number(product.price * currency.factor).toFixed(2)}
                                                            </Col>
                                                       </Row>
                                                  </React.Fragment>
                                             )
                                             )
                                        }

                                   </Col>

                              </Row>
                         </React.Fragment>
                         :
                         <Row>
                              <Col className="col-3 mx-auto"><Image className="img-fluid" src={product.photo || null} alt="product_image" width="80" height="80" /></Col>
                              <Col className="col-9 mx-auto">
                                   <h5>{t(product.translationCodeName)} <i className="fa fa-window-close" aria-hidden="true"></i></h5>
                                   <p><small>#{product.uid}</small></p>
                                   {currency.symbol} {currency.code === "JPY" ? Number(product.price * currency.factor).toFixed(0) : Number(product.price * currency.factor).toFixed(2)} &nbsp; <input type="number" min="1" max="10" defaultValue="1" />
                              </Col>
                         </Row>

               }
               <hr />
               <Row>
                    <Col className="col-3 mx-auto"></Col>
                    <Col className="col-6 mx-auto"><strong>{t('subtotal')}</strong></Col>
                    <Col className="col-3 mx-auto">&nbsp; {currency.symbol} {currency.code === "JPY" ? Number(total * currency.factor).toFixed(0) : Number(total * currency.factor).toFixed(2)}</Col>
               </Row>
               <Row>
                    <Col className="col-3 mx-auto"></Col>
                    <Col className="col-6 mx-auto">{t('shipping_costs')} ({t('standard')})</Col>
                    <Col className="col-3 mx-auto">+ {currency.symbol} {currency.code === "JPY" ? Number(shipping * currency.factor).toFixed(0) : Number(shipping * currency.factor).toFixed(2)}</Col>
               </Row><br />
               <Row>
                    <Col className="col-3 mx-auto"></Col>
                    <Col className="col-9 mx-auto">
                         <div className="input-group">
                              <input type="text" className="form-control" placeholder={t('gift_card_or_disc_code')} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                              <div className="input-group-append">
                                   <button className="btn btn-outline-secondary" style={{ border: '1px solid black' }} type="button"><i className="fa fa-check" aria-hidden="true"></i></button>
                              </div>
                         </div>
                    </Col>
               </Row><br />
               <Row>
                    <Col className="col-3 mx-auto"></Col>
                    <Col className="col-9">
                         <Link to="/checkout"><button className="btn-default btn-block text-uppercase">{t('proceed_to_checkout')}</button><br /></Link>
                         <AmazonPayButton id="ap-button-sidepane" />
                         <br />
                         <CreateSBBuyer/>
                         <Link to="/cart"><button className="btn btn-block text-uppercase mt-4">{t('go_to_shopping_cart')}</button></Link>
                    </Col>
               </Row>
          </div>
     )

}

export default PaneDetails;

const Title = styled.h1`
  font-size: 1em;
  text-align: left;
  color: green;
  flex-wrap: wrap;
`;
const TitleAlert = styled.p`
  font-size: 1em;
  text-align: left;
  color: white;
  background: #44CFF;
  flex-wrap: wrap;
`;
const Wrapper = styled.section`
  background: #D5F3DB;
  padding: 0.5em;
`;
const WrapperAlert = styled.section`
  background: #087AFF;
  padding: 0.5em;
`;