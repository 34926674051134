import Currencies from "src/types/Currencies";
import {doesContainDigitalProduct} from  '../helpers/customHelperFunctions';
interface ICartItem {
    id: number;
    name: string;
    translationCodeName: string;
    price: number;
    type: string;
    photo: string;
    details: string;
    uid: string;
    quantity: number;
}

type CartItemCollection = Array<ICartItem>;

const Storage = (cartItems: CartItemCollection) => {
    localStorage.setItem('cart', JSON.stringify(cartItems.length > 0 ? cartItems : []));
}

export const sumItems = (cartItems: CartItemCollection) => {
    Storage(cartItems);
    const currency = Currencies.getCurrentCurrency();
    let itemCount = cartItems.reduce((total, product) => total + product.quantity, 0);
    let total = cartItems.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2);
    let shipping = doesContainDigitalProduct(cartItems)? Number(0) : Number(4).toFixed(2); // use round number to avoid decimal numbers (as not supported in JP)
    let netTotal = (Number(total) + Number(shipping));
    let vat = Number(netTotal) * 0.1;
    let finalTotal = Number(netTotal) + Number(vat);
    localStorage.setItem('finalTotal', String(Number(finalTotal).toFixed(2))); // NOTE: finalTotal always annotated in USD
    return { itemCount, total, shipping, netTotal, vat, finalTotal }
}

export const CartReducer = (state: any, action: any) => {
    switch (action.type) {
        case "ADD_ITEM":
            if (!state.cartItems.find((item: any) => item.id === action.payload.id)) {
                state.cartItems.push({
                    ...action.payload,
                    quantity: 1
                })
            }

            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "REMOVE_ITEM":
            return {
                ...state,
                ...sumItems(state.cartItems.filter((item: any) => item.id !== action.payload.id)),
                cartItems: [...state.cartItems.filter((item: any) => item.id !== action.payload.id)]
            }
        case "INCREASE":
            state.cartItems[state.cartItems.findIndex((item: any) => item.id === action.payload.id)].quantity++
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "DECREASE":
            state.cartItems[state.cartItems.findIndex((item: any) => item.id === action.payload.id)].quantity--
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "CHECKOUT":
            return {
                cartItems: [],
                checkout: true,
                ...sumItems([]),
            }
        case "CLEAR":
            return {
                cartItems: [],
                ...sumItems([]),
            }
        default:
            return state
    }
}