import { useEffect, useState } from 'react'
import useScript from './useScript';
import useLocalStorage from './useLocalStorage'
import { getLocaleRegion } from 'src/helpers/customHelperFunctions'

export type Status = 'loading' | 'ready' | 'error'

function useAmazonPayScript(): Status {
  const locale = getLocaleRegion();
  const [status, setStatus] = useState<Status>('loading')
  const [region, setRegion] = useLocalStorage('region', locale);

  let checkoutJsPath: string;

  switch (region) {
    case 'eu':
    case 'uk':
      checkoutJsPath = 'https://static-eu.payments-amazon.com/checkout.js';
      break;
    case 'jp':
      checkoutJsPath = 'https://static-fe.payments-amazon.com/checkout.js';
      break;
    default:
      checkoutJsPath = 'https://static-na.payments-amazon.com/checkout.js';
  }

  const jsScriptStatus = useScript(checkoutJsPath);

  useEffect(
    () => {
      setStatus(jsScriptStatus);
    },
    [region, jsScriptStatus], // Only re-run effect if region changes
  )


  return jsScriptStatus
}

export default useAmazonPayScript
