import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types';
import Currencies from '../../types/Currencies';
import Languages from '../../types/Languages';
import Language from '../../types/Language';
import Environment from '../../types/Environment';
import useAmazonPayScript from '../../custom-hooks/useAmazonPayScript';
import useConfig from '../../custom-hooks/useConfig';
import { CartContext } from '../../contexts/CartContext';
import { ProductsContext } from '../../contexts/ProductsContext';
import { BASE_RETURN_URL } from 'src/helpers/customHelperFunctions';
import ApiGateway from 'src/helpers/ApiGateway';

export interface AmazonPayCustomButtonProps {
  id: string,
  productID?: number
}

declare const amazon: any

export function AmazonPayCustomButton({ id, productID }: AmazonPayCustomButtonProps) {

  const [configStatus, config, configError] = useConfig();
  const amazonPayScriptStatus = useAmazonPayScript();

  const [flow, setFlow] = useState("Physical");

  const { finalTotal, cartItems, addProduct } = useContext(CartContext);
  const product = useContext(ProductsContext)!.find(obj => obj.id == productID)!;

  const currency = Currencies.getCurrentCurrency();
  const language = Languages.getCurrentLanguage();

  // Hook that sets button div
  const [amazonPayButton, setAmazonPayButton] = useState(null);
  // A timeout hook for useEffect
  const [count, setCount] = useState(50);
  const api = new ApiGateway();

  useEffect(() => {
    // if config and AP checkout.js have loaded, and amazon namespace is available, render the button
    if (configStatus == 'ready' && amazonPayScriptStatus == 'ready') {
      // setAmazonPayButton(renderAmazonPayButton(id, flow));
      if ((cartItems.length === 0 || (cartItems.length === 1 && cartItems[0]?.id === 4)) && productID === 4) {
        setFlow("Digital");
        setAmazonPayButton(renderAmazonPayButton(id, "PayOnly"));
      } else if ((cartItems.length === 0 || (cartItems.length === 1 && cartItems[0]?.id === 3)) && productID === 3) {
        setFlow("Recurring");
        setAmazonPayButton(renderAmazonPayButton(id, "PayAndShip"));
      } else {
        setFlow("Physical");
        setAmazonPayButton(renderAmazonPayButton(id, "PayAndShip"));
      }

      // Setting some timeout to reflect the prop changes due to collison with useEffect hook
      setTimeout(() => {
        setCount(150);
      }, 1000);
    }
  }, [amazonPayScriptStatus, configStatus]);

  return (
    <React.Fragment>
      <div id={id} className="amazon-pay-button" onClick={() => renderAmazonPayButtonScript(amazonPayButton)} ></div>
    </React.Fragment>
  )

  async function getSignature(region: string, returnUrl: string) {
    let encodedReturnUrl = encodeURIComponent(returnUrl);
    let url = Environment.apiGatewayUrl + '/generateButtonSignature?region=' + region + '&returnUrl=' + encodedReturnUrl;
    let signature = api.generateButtonSignature(url);
    return signature;
  }

  async function getSignatureForRecurring(region: string, returnUrl: string) {
    let encodedReturnUrl = encodeURIComponent(returnUrl);
    let url = Environment.apiGatewayUrl + '/generateButtonSignature?region=' + region + '&returnUrl=' + encodedReturnUrl +
      '&checkoutFlow=' + flow + '&amount=' + (currency.code === "JPY" ? String(finalTotal.toFixed(0)) : String(finalTotal.toFixed(2))) + '&currencyCode=' + currency.code +
      '&frequencyUnit=' + (window.sessionStorage.getItem("frequencyUnit") ? window.sessionStorage.getItem("frequencyUnit") : "Month") + '&frequencyValue=' + (window.sessionStorage.getItem("frequencyValue") ? window.sessionStorage.getItem("frequencyValue") : "1");
    let signature = api.generateButtonSignature(url);
    return signature;
  }

  // based on the given region, pick the best suiteable CV2 checkout language
  // NOTE: CV2 supportes US English only for US region, and GB English for UK, which is accomodated here
  function pickCheckoutLanguage(region: string, language: Language): string {
    if (region == 'jp') return Languages.Japanese.fourLetterCode; // as ja-JP is the only supported language for JP
    if (region == 'us') return Languages.English.fourLetterCode; // as en-US is the only supported language for US

    if (region == 'uk' || region == 'eu') {
      if (language == Languages.English) return 'en-GB'; // en_US not available in the EU/UK, so reset to en_GB
    }
    return language.fourLetterCode; // otherwise, use the language that was already set
  }

  function renderAmazonPayButton(id, productType) {
    var amazonPayButton = amazon.Pay.renderButton('#' + id, {
      merchantId: config.merchantId,
      ledgerCurrency: currency.code,
      sandbox: true,
      checkoutLanguage: pickCheckoutLanguage(config.region, language).replace('-', '_'),
      productType: productType,
      placement: "Product",
      buttonColor: "Gold",
      publicKeyId: config.publicKeyId
    });
    return amazonPayButton;
  }

  // InitCheckout function, which onClick will perform the CXSession creation task
  async function renderAmazonPayButtonScript(amazonPayButton) {
    amazonPayButton.onClick(async function () {
      addProduct(product);

      if (cartItems.length === 1 && cartItems[0]?.id === 4) {
        setFlow("Digital");
      } else if (cartItems.length === 1 && cartItems[0]?.id === 3) {
        setFlow("Recurring")
      } else {
        setFlow("Physical")
      }
      var baseReturnUrl = BASE_RETURN_URL;
      if (flow === "Digital") {
        baseReturnUrl += '/review?flow=Digital';
      } else if (flow === "Recurring") {
        baseReturnUrl += '/review?flow=Recurring';
      } else {
        baseReturnUrl += '/review';
      }
      const signatureGeneration = flow === "Recurring" ? JSON.parse(await getSignatureForRecurring(config.region, baseReturnUrl)) : JSON.parse(await getSignature(config.region, baseReturnUrl));

      console.log("Payload - " + JSON.stringify(signatureGeneration.payload));

      amazonPayButton.initCheckout({
        createCheckoutSessionConfig: {
          payloadJSON: JSON.stringify(signatureGeneration.payload),
          signature: signatureGeneration.signature,
          publicKeyId: config.publicKeyId
        }
      });
    });
  }
}

AmazonPayCustomButton.propTypes = {
  id: PropTypes.string
}

export default AmazonPayCustomButton;
