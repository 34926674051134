import Currency from './Currency';
import { getDefaultLocale } from 'src/helpers/customHelperFunctions';

class Currencies {

  public static usdollar: Currency;
  public static euro: Currency;
  public static britishpounds: Currency;
  public static yen: Currency;

  private static currencies: Array<Currency>;

  public static initialize() {
    this.currencies = new Array<Currency>();

    Currencies.usdollar = new Currency("USD", "$");
    this.currencies.push(Currencies.usdollar);

    Currencies.euro = new Currency("EUR", "€");
    this.currencies.push(Currencies.euro);

    Currencies.britishpounds = new Currency("GBP", "£");
    this.currencies.push(Currencies.britishpounds);

    Currencies.yen = new Currency("JPY", "¥", 100);
    this.currencies.push(Currencies.yen);
  }

  public static getCurrencyFromLocale() {
    const locale = getDefaultLocale();
    switch (locale) {
      case "us": return Currencies.usdollar;
      case "uk": return Currencies.britishpounds;
      case "jp": return Currencies.yen;
      default: return Currencies.euro;
    }
  }

  public static getCurrencyFromCurrencyCode(currencyCode: string) {
    let currency = Currencies.currencies.find((e) => e.code === currencyCode)!;
    return currency ? currency : this.getCurrencyFromLocale();
  }

  public static getCurrentCurrency(): Currency {
    let storedCurrency = JSON.parse(localStorage.getItem('currency') || null);
    let currency = storedCurrency ? storedCurrency : this.getCurrencyFromLocale();
    return currency;
  }

  public static setCurrentCurrency(currencyCode: string): void {
    let currency = this.getCurrencyFromCurrencyCode(currencyCode);
    localStorage.setItem('currency', JSON.stringify(currency));
  }

}

Currencies.initialize();
export default Currencies;