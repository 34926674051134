import React from 'react'
import { Image, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import plusIcon from 'src/assets/vectors/mobilemenuicon-plus.svg';

const FooterColumn = (props) => {

    const { t } = useTranslation();
    const [isCollapsed, SetIsCollapsed] = React.useState(true);
    let list = props.props.list;
    let category = props.props.category;
    let link = props.props.link!;

    return (
        <Col lg={2} className={`footer-column${isCollapsed ? '' : '-expanded'}`}>
            <div className="d-flex justify-content-between">
                <h6 className="text-uppercase">{t(category)}</h6><Image className="mobile-plus-icon" src={plusIcon} onClick={() => SetIsCollapsed(!isCollapsed)}></Image>
            </div>
            <ul className="list-unstyled mb-0 body5">
                {list.map((item) => {
                    if (item.link.length > 0 && link) {
                        return <li key={item}><a href={link[list.indexOf(item)]}>{t(item)}</a></li>
                    }
                    else {
                        return <li key={item}>{t(item)}</li>
                    }
                })}
            </ul>
        </Col>
    )

}

export default FooterColumn;