import React, { useEffect, useState, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import { Container, Row, Col, Image } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowIosBackOutline as LeftArrow } from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import Address from '../../components/checkout/Address';
import Currencies from '../../types/Currencies';
import { ChargePermission, CheckoutSessionDetails } from '../../types/WebCheckoutTypes';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../contexts/CartContext';
import useConfig from '../../custom-hooks/useConfig';
import ApiGateway, { IApiError } from '../../helpers/ApiGateway';
import UrlHepler from '../../helpers/UrlHelper';
import LoginHelper from '../../helpers/LoginHelper';
import useAmazonPayScript from '../../custom-hooks/useAmazonPayScript';
import { doesContainDigitalProduct } from '../../helpers/customHelperFunctions';
import { BillingAddressRetrieveFromChargePermission } from '../../components/checkout/AddressRetriever';
import { ShippingAddressRetrieveFromChargePermission } from '../../components/checkout/AddressRetriever';
import OrderSummary from 'src/helpers/OrderSummary';
import { BASE_RETURN_URL } from 'src/helpers/customHelperFunctions';

declare const amazon: any

export default function Confirm() {

    const { t } = useTranslation();

    const currency = Currencies.getCurrentCurrency();

    const [configStatus, config] = useConfig();
    const [isPaySelected, setIsPaySelected] = useState(false);
    const [isOrderFinalized, setIsOrderFinalized] = useState(false);
    const [checkoutSessionData, setCheckoutSessionData] = useState(null as CheckoutSessionDetails);
    const [chargePermissionData, setChargePermissionData] = useState(null as ChargePermission);
    const [region, setRegion] = useState('');
    const amazonPayScriptStatus = useAmazonPayScript();

    const [api] = useState(new ApiGateway());
    const cart = useContext(CartContext);
    const [items] = useState(cart.cartItems);
    const total = useState(cart.total);
    const vat = useState(cart.vat);
    const shipping = useState(cart.shipping);
    const finalTotal = useState(cart.finalTotal);
    const netTotal = useState(cart.netTotal);

    const orderDetails = {
        total: total[0],
        vat: vat[0],
        shipping: shipping[0],
        finalTotal: finalTotal[0],
        netTotal: netTotal[0],
        cartItems: items
    }

    function doLogout() {
        LoginHelper.logout();
        try {
            /* attempt to signout from Amazon account, but don't error if unsuccessful */
            amazon.Pay.signout();
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(
        () => {
            if (configStatus === 'ready') {

                api.setRegion(config.region);
                setRegion(config.region);

                // if an CheckoutSessionId is in the URL, call Complete CheckoutSession and Get ChargePermission
                let checkoutSessionId = UrlHepler.getUrlParam("amazonCheckoutSessionId");
                if (checkoutSessionId) {

                    // if CheckoutSession data not present, load it via the Complete CheckoutSession call
                    if (checkoutSessionData == null) {

                        setIsPaySelected(true);
                        // execute Complete CheckoutSession to finalize transacton
                        api.completeCheckoutSession(checkoutSessionId, currency).then(ccDetails => {
                            // when failed, send buyer back to Cart page, otherwise to call Get ChargePermission to retrieve details
                            if (ccDetails.statusDetails.state !== "Completed") {
                                window.location.href = BASE_RETURN_URL + "/cart?ap-error=" + ccDetails.statusDetails.reasonCode;
                            } else {
                                setCheckoutSessionData(ccDetails);
                            }
                        }).catch(error => {
                            let apiError = error as IApiError;
                            if (apiError && apiError.httpStatusCode === 422) {
                                window.location.href = BASE_RETURN_URL + "/cart?ap-error=" + apiError.reasonCode;
                            } else {
                                console.error(error);
                            }
                        });

                    } else { // if CheckoutSession data present already instead, retrieve details via Get ChargePermission call

                        api.getChargePermission(checkoutSessionData.chargePermissionId).then(cpDetails => {
                            setChargePermissionData(cpDetails);
                            setIsOrderFinalized(true); // when everyhing loaded, show the ThankYou message
                        }).catch(reason => {
                            console.log(reason);
                        });
                        cart.clearCart();
                    }

                } else { // if no CheckoutSessionId is in the URL, simply show the Thank You message
                    setIsOrderFinalized(true);
                }

            }
        }, [configStatus, checkoutSessionData, items]
    );

    const Processing = () => (
        <Container className="pt-5 pb-5"><br />
            <Row>
                <Col className="">
                    <Spinner animation="border" role="status" className="align-middle" />
                    {/* // TODO: add translation */}
                    <span className="align-middle ml-3">{t('verifying_payment')}</span>
                </Col>
            </Row>
        </Container>
    );

    const AddressSelector = () => {
        if (doesContainDigitalProduct(items) === true) {
            return (
                <Col className="col-12">
                    <Row>
                        <Col className="col-sm-4 col-12 mt-2 border-bottom mr-4 font-weight-bold text-uppercase">{t('billing_address')}</Col>
                        <Col className="col-3 d-sm-block d-none mt-2 border-bottom font-weight-bold text-uppercase">{t('information')}</Col>
                    </Row>
                    <Row>
                        <Col className="col-sm-4 col-12 mt-2 mr-4">
                            <BillingAddressRetrieveFromChargePermission chargePermissionData={chargePermissionData} region={region} />
                        </Col>
                        <Col className="col-12 d-sm-none d-block mt-2 border-bottom font-weight-bold text-uppercase">{t('information')}</Col>
                        <Col className="col-sm-3 col-12 mt-2 ">
                            <p><span className="font-weight-bold">{t('payment_method')}:</span> {isPaySelected ? "Amazon Pay" : t('credit_card_debit_card')}</p>
                        </Col>
                    </Row>
                </Col>
            )
        } else {
            return (
                <Col className="col-12">
                    <Row>
                        <Col className="col-sm-4 col-12 mt-2 border-bottom mr-4 font-weight-bold text-uppercase">{t('billing_address')}</Col>
                        <Col className="col-4 d-sm-block d-none mt-2 border-bottom mr-4  font-weight-bold text-uppercase">{t('shipping_address')}</Col>
                        <Col className="col-3 d-sm-block d-none mt-2 border-bottom font-weight-bold text-uppercase">{t('information')}</Col>
                    </Row>
                    <Row>
                        <Col className="col-sm-4 col-12 mt-2 mr-4">
                            <BillingAddressRetrieveFromChargePermission chargePermissionData={chargePermissionData} region={region} />
                        </Col>
                        <Col className="col-12 d-sm-none d-block mt-2 border-bottom font-weight-bold text-uppercase">{t('shipping_address')}</Col>
                        <Col className="col-sm-4 col-12 mt-2 mr-4">
                            <ShippingAddressRetrieveFromChargePermission chargePermissionData={chargePermissionData} region={region} />
                        </Col>
                        <Col className="col-12 d-sm-none d-block mt-2 border-bottom font-weight-bold text-uppercase">{t('information')}</Col>
                        <Col className="col-sm-3 col-12 mt-2 ">
                            <p><span className="font-weight-bold">{t('payment_method')}:</span> {isPaySelected ? "Amazon Pay" : t('credit_card_debit_card')}</p>
                            <p><span className="font-weight-bold">{t('shipping_method')}:</span> {t('standard_cap_letter')}</p>
                        </Col>
                    </Row>
                </Col>

            )
        }
    }

    const ThankYou = () => (
        <Container >
            <Row className="justify-content-start offset-mt-2 mt-2">
                <Anchor to="/" onClick={() => cart.clearCart()}><ArrowL /> {t('continue_shopping')}</Anchor>
            </Row><br />
            <Row>
                <Col className="justify-content-start col-12">
                    <Row>
                        <Col className="text-center"><h3>{t('thank_you_for_your_order')}</h3></Col>
                    </Row>
                    <Row className="mt-2">
                        {/* // TODO: use actual order number */}
                        <Col className="text-center"><span className="font-weight-bold">{t('your_order_number_10079')}{chargePermissionData?.chargePermissionId ? chargePermissionData?.chargePermissionId : '#10079'}</span></Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="text-center">{t('order_confirmation_sent')}</Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="text-center">
                            <AnchorLogout to="/" onClick={() => doLogout()}>{t('sign_out_from_pay')}</AnchorLogout>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-start mb-4 mt-4">
                <AddressSelector />
            </Row>
            <Row className="justify-content-start mb-4 mt-4">
                <Col className="col-12">
                    <hr />
                    <Row>
                        <Col className="d-none d-sm-inline col-4 font-weight-bold text-uppercase">{t('product')}</Col>
                        <Col className="d-none d-sm-inline col-3 font-weight-bold text-uppercase">{t('quantity')}</Col>
                        <Col className="d-none d-sm-inline col-2 font-weight-bold text-uppercase">{t('item_price')}</Col>
                        <Col className="d-none d-sm-inline col-3 font-weight-bold text-uppercase">{t('subtotal')}</Col>
                    </Row>
                    <hr />
                    <Row>
                        {
                            items.map(product => (
                                <React.Fragment key={product.id}>
                                    <Col className="col-sm-2 col-4">
                                        <Image className="pull-left" src={product.photo} width="60" height="60" />
                                    </Col>
                                    <Col className="col-sm-2 col-6">
                                        <b>{t(product.translationCodeName)}</b><br /><small>{product.uid}</small>
                                    </Col>
                                    <Col className="col-sm-3 col-6">
                                        <b style={{ alignItems: 'center', display: 'flex' }}><span className="d-sm-none d-inline">{t('quantity')}:</span>{product.quantity}</b>
                                    </Col>
                                    <Col className="col-sm-2 col-3">
                                        {currency.symbol} {currency.code === "JPY" ? Number(product.price * currency.factor).toFixed(0) : Number(product.price * currency.factor).toFixed(2)}
                                    </Col>
                                    <Col className="col-3">
                                        <strong>{currency.symbol} {currency.code === "JPY" ? Number(product.price * currency.factor).toFixed(0) : Number(product.price * currency.factor * product.quantity).toFixed(2)}</strong>
                                    </Col>
                                </React.Fragment>
                            ))
                        }
                    </Row>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col className="col-sm-4 col-12">
                    <OrderSummary orderDetails={orderDetails} />
                    <br />
                </Col>
            </Row>
        </Container>
    );

    return (
        <Container className="main"><br />
            {isOrderFinalized ? <ThankYou /> : <Processing />}
        </Container>
    )

}

const ArrowL = styled(LeftArrow)`
    color: black;
    height: 30px;
    width: 30px;
`;
const Anchor = styled(Link)`
    color: black;
    text-color: black;
    font-weight: bold;
`;
const AnchorLogout = styled(Link)`
    color: orange;
    text-color: black;
    font-weight: bold;
`;