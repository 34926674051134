import React, { useContext, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowIosBackOutline as LeftArrow } from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import AmazonPayButton from '../../components/amazon-pay-button/AmazonPayButton';
import Currencies from '../../types/Currencies';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../contexts/CartContext';
import { useHistory } from "react-router-dom";
import LoginHelper from '../../helpers/LoginHelper';
import { APBAddress } from '../../helpers/customHelperFunctions';
import OrderSummary from 'src/helpers/OrderSummary';
import CreateSBBuyer from 'src/helpers/CreateSBBuyer';

export default function Checkout() {

     const [countryOptions] = React.useState([
          { value: 'US', label: 'United States' },
          { value: 'UK', label: 'United Kingdom' },
          { value: 'GB', label: 'Great Britain' },
          { value: 'DE', label: 'Germany' },
          { value: 'FR', label: 'France' },
          { value: 'IT', label: 'Italy' },
          { value: 'ES', label: 'Spain' },
          { value: 'AT', label: 'Austria' },
          { value: 'BE', label: 'Belgium' },
          { value: 'BG', label: 'Bulgaria' },
          { value: 'HR', label: 'Croatia' },
          { value: 'CY', label: 'Cyprus' },
          { value: 'CZ', label: 'Czech Republic' },
          { value: 'DK', label: 'Denmark' },
          { value: 'EE', label: 'Estonia' },
          { value: 'FI', label: 'Finland' },
          { value: 'GR', label: 'Greece' },
          { value: 'HU', label: 'Hungary' },
          { value: 'IE', label: 'Ireland' },
          { value: 'LV', label: 'Latvia' },
          { value: 'LT', label: 'Lithuania' },
          { value: 'LU', label: 'Luxembourg' },
          { value: 'MT', label: 'Malta' },
          { value: 'NL', label: 'Netherlands' },
          { value: 'PL', label: 'Poland' },
          { value: 'PT', label: 'Portugal' },
          { value: 'RO', label: 'Romania' },
          { value: 'SK', label: 'Slovakia' },
          { value: 'SI', label: 'Slovenia' },
          { value: 'SE', label: 'Sweden' },
          { value: 'JP', label: 'Japan' },
     ]);

     let history = useHistory();

     const { t } = useTranslation();
     const orderDetails = useContext(CartContext);
     const cartItems = orderDetails.cartItems;

     const currency = Currencies.getCurrentCurrency();
     const APBAddressDetails = APBAddress(currency.code);

     const [firstName, setFirstName] = useState(APBAddressDetails.firstName);
     const [lastName, setLastName] = useState(APBAddressDetails.lastName);
     const [emailAddress, setEmailAddress] = useState("example@amazon.com");
     const [password, setPassword] = useState("1234567890000");
     const [streetAddress, setStreetAddress] = useState(APBAddressDetails.streetAddress);
     const [city, setCity] = useState(APBAddressDetails.city);
     const [state, setState] = useState(APBAddressDetails.state);
     const [postalCode, setPostalCode] = useState(APBAddressDetails.postalcode);
     const [country, setCountry] = useState(APBAddressDetails.country);
     const [phoneNumber, setPhoneNumber] = useState(APBAddressDetails.phoneNumber);

     const handleSubmit = e => {
          e.preventDefault();

          if (cartItems.length === 1 && cartItems[0].id === 4) {
               history.push({
                    pathname: '/review',
                    search: '?flow=APB',
                    state: { details: null }
               });
          } else {
               const data = {
                    firstName: firstName,
                    lastName: lastName,
                    emailAddress: emailAddress,
                    password: password,
                    streetAddress: streetAddress,
                    city: city,
                    state: state,
                    postalCode: postalCode,
                    countryCode: country,
                    phoneNumber: phoneNumber
               }
               let formdata = JSON.stringify(data);
               window.sessionStorage.setItem("apbAddressDetails", formdata);
               history.push({
                    pathname: '/review',
                    search: '?flow=APB',
                    state: { details: formdata }
               });
          }
     }

     function getAccountLink() {
          const [isAuthenticated, login] = LoginHelper.loginIsValid;
          if (isAuthenticated) {
               if (login !== null && login.token?.length > 0) {
                    return `/account?buyerToken=${login.token}`;
               }

          }
          return "/login";
     }

     function ShippingAddressComponent() {
          if (cartItems.length === 1 && cartItems[0].id === 4) {
               return null;
          } else {
               return (
                    <React.Fragment>
                         <hr />
                         <Row>
                              <Col><h3>{t('shipping_address')}</h3></Col>
                         </Row><br />
                         <Row>
                              <Col className="col-6">{t('street_address')}*</Col>
                              <Col className="col-6">{t('city')}*</Col>
                         </Row>
                         <Row>
                              <Col className="col-6"><input type="text" className="form-control" required placeholder={t('enter_street_address')} onChange={(event) => setStreetAddress(event.target.value)} value={streetAddress} /></Col>
                              <Col className="col-6"><input type="text" className="form-control" required placeholder={t('enter_city')} onChange={(event) => setCity(event.target.value)} value={city} /></Col>
                         </Row><br />
                         <Row>
                              {/* ToDo: Add String Translation - For StateorRegion */}
                              <Col className="col-6">{t('state_or_region')}</Col>
                              <Col className="col-6">{t('postal_code')}*</Col>
                         </Row>
                         <Row>
                              <Col className="col-6"><input type="text" className="form-control" placeholder={t('enter_state_or_region')} onChange={(event) => setState(event.target.value)} value={state} /></Col>
                              <Col className="col-6"><input type="text" className="form-control" required placeholder={t('enter_postal_code')} onChange={(event) => setPostalCode(event.target.value)} value={postalCode} /></Col>
                         </Row><br />
                         <Row>
                              <Col className="col-6">{t('country')}</Col>
                              <Col className="col-6">{t('phone_number')}*</Col>
                         </Row>
                         <Row>
                              {/* ToDo: Add String Translation - PhoneNumber */}
                              <Col className="col-6">
                                   <select className="form-control" name="country" id="country" onChange={(event) => setCountry(event.target.value)} value={APBAddressDetails.country}>
                                        {countryOptions.map(item => (
                                             <option key={item.value} value={item.value}>
                                                  {item.label}
                                             </option>
                                        ))}
                                   </select>
                              </Col>
                              <Col className="col-6"><input type="text" className="form-control" required placeholder={t('enter_phone_number')} onChange={(event) => setPhoneNumber(event.target.value)} value={phoneNumber} /></Col>
                         </Row><br />
                         <Row>
                              <Col>
                                   <input type="checkbox" style={{ border: 'red' }} placeholder={t('enter_last_name')} />
                                   &nbsp; {t('checkout_billing_same_as_shipping')}
                              </Col>
                         </Row><br />
                    </React.Fragment>
               );
          }
     }

     return (
          <Container className="main"><br />
               <Row className="mx-auto">
                    <Anchor to="/cart"><Arrow /> {t('back_to_cart')}</Anchor>
               </Row><br />
               <Row>
                    <Col className="col-sm-8 col-12">
                         <Row>
                              <Col><h3>{t('checkout')}</h3></Col>
                              <Col>{t('checkout_required_fields')}</Col>
                         </Row>
                         <Section>
                              <Row className="mt-4">
                                   <Col>
                                        <b>{t('checkout_existing_amz_customer')}</b><br />
                                        {t('checkout_skip_the_line')}
                                   </Col>
                              </Row>
                              <Row className="mt-2">
                                   <Col className="col-5">
                                        <AmazonPayButton id="ap-button-checkout" />
                                        <br />
                                        <CreateSBBuyer />
                                   </Col>
                              </Row>
                         </Section>
                         <br />
                         <Row>
                              <Col>{t('checkout_already_have_account_1')} <Anchor to={getAccountLink()}><b>{t('checkout_already_have_account_2')}</b></Anchor> {t('checkout_already_have_account_3')}</Col>
                         </Row>
                         <hr />
                         <Row>
                              {/* ToDo: Add String Translation - Placeholder*/}
                              <Col><h5>{t('apb_placeholder')}</h5></Col>
                         </Row><br />
                         <form onSubmit={handleSubmit}>
                              <Row>
                                   {/* To-Do: String translation */}
                                   <Col><h3>{t('wont_be_saved')}</h3></Col>
                              </Row><br />
                              {
                                   currency.code === 'JPY' ?
                                        <React.Fragment>
                                             <Row>
                                                  <Col className="col-6">{t('last_name')}*</Col>
                                                  <Col className="col-6">{t('first_name')}*</Col>
                                             </Row>
                                             <Row>
                                                  <Col className="col-6"><input type="text" className="form-control" name="lastName" id="lastName" required placeholder={t('enter_last_name')} onChange={(event) => setLastName(event.target.value)} value={lastName} /></Col>
                                                  <Col className="col-6"><input type="text" className="form-control" name="firstName" id="firstName" required placeholder={t('enter_first_name')} onChange={(event) => setFirstName(event.target.value)} value={firstName} /></Col>
                                             </Row>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                             <Row>
                                                  <Col className="col-6">{t('first_name')}*</Col>
                                                  <Col className="col-6">{t('last_name')}*</Col>
                                             </Row>
                                             <Row>
                                                  <Col className="col-6"><input type="text" className="form-control" name="firstName" id="firstName" required placeholder={t('enter_first_name')} onChange={(event) => setFirstName(event.target.value)} value={firstName} /></Col>
                                                  <Col className="col-6"><input type="text" className="form-control" name="lastName" id="lastName" required placeholder={t('enter_last_name')} onChange={(event) => setLastName(event.target.value)} value={lastName} /></Col>
                                             </Row>
                                        </React.Fragment>
                              }
                              <br />
                              <Row>
                                   <Col>
                                        <input type="checkbox" style={{ border: 'red' }} />
                                        &nbsp; {t('checkout_dont_create_account')}
                                   </Col>
                              </Row><br />
                              <Row>
                                   <Col>{t('email_address')}*</Col>
                              </Row>
                              <Row>
                                   <Col><input type="email" className="form-control" required placeholder={t('enter_email_address')} onChange={(event) => setEmailAddress(event.target.value)} value={emailAddress} /></Col>
                              </Row><br />
                              <Row>
                                   <Col>{t('password')}*</Col>
                              </Row>
                              <Row>
                                   <Col><input type="password" className="form-control" required placeholder={t('enter_password')} onChange={(event) => setPassword(event.target.value)} value={password} /></Col>
                              </Row>
                              <Row>
                                   <Col>{t('checkout_password_min_length')}</Col>
                              </Row><br />

                              <ShippingAddressComponent />
                              <Row>
                                   <Col>
                                        <Button>{t('checkout_continue_order_review')}</Button>
                                   </Col> <br /><br />
                              </Row>
                         </form>
                    </Col>
                    <Col className="col-sm-4 col-12">
                         <OrderSummary orderDetails={orderDetails} />
                         <br />
                    </Col>
               </Row>
               <br />
          </Container>
     )
}

const Arrow = styled(LeftArrow)`
    color: black;
    height: 30px;
    width: 30px;
`;
const Anchor = styled(Link)`
    color: black;
    text-color: black;
    font-weight: bold;
`;
const Section = styled.section`
  color: black;

  /* Pass variables as inputs */
  padding: 1rem;
  /* Adjust the background from the properties */
  border: 1px solid #dee2e6 !important;
  background-color: #f8f9fa !important;
`;
const Button = styled.button`
    background: black;
    color:  white;
    text-color: white;
    width: 435px;
    height: 44px;
    font-size: 1em;
    // margin: 0em;
    // padding: 0.25em 1em;
    border: 1px solid black;
    border-radius: 3px;
    @media (max-width: 768px) {
         width:100%;
    }
`;