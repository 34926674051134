export const refreshPage = () => {
  window.location.reload();
}

type APBAddressDetails = {
  firstName: string;
  lastName: string;
  streetAddress: string;
  city: string;
  state: string;
  postalcode: string;
  country: string;
  phoneNumber: string;
}

export const APBAddress = (currencyCode) => {
  let APBAddressDetailsObject: APBAddressDetails = {
    firstName: "Susie",
    lastName: "Smith",
    streetAddress: "10 Ditka Ave",
    city: "Chicago",
    state: "IL",
    postalcode: "60602",
    country: "US",
    phoneNumber: "800-000-0000",
  }
  if (currencyCode === "EUR") {
    APBAddressDetailsObject.firstName = "Karl";
    APBAddressDetailsObject.lastName = "Küfer";
    APBAddressDetailsObject.streetAddress = "Schützstraße 123";
    APBAddressDetailsObject.city = "München";
    APBAddressDetailsObject.state = "";
    APBAddressDetailsObject.country = "DE";
    APBAddressDetailsObject.postalcode = "80939";
    APBAddressDetailsObject.phoneNumber = "+491731112222";
  } else if (currencyCode === "GBP") {
    APBAddressDetailsObject.firstName = "Jane";
    APBAddressDetailsObject.lastName = "Doe";
    APBAddressDetailsObject.streetAddress = "419 Kings Road";
    APBAddressDetailsObject.city = "Chelsea";
    APBAddressDetailsObject.state = "";
    APBAddressDetailsObject.country = "GB";
    APBAddressDetailsObject.postalcode = "SW3 4ND";
    APBAddressDetailsObject.phoneNumber = "800-000-0000";
  } else if (currencyCode === "JPY") {
    APBAddressDetailsObject.firstName = "山田";
    APBAddressDetailsObject.lastName = "太郎";
    APBAddressDetailsObject.streetAddress = "下目黒1-8-1";
    APBAddressDetailsObject.city = "目黒区";
    APBAddressDetailsObject.state = null;
    APBAddressDetailsObject.country = "JP";
    APBAddressDetailsObject.postalcode = "153-0064";
    APBAddressDetailsObject.phoneNumber = "0312345678";
  }
  return APBAddressDetailsObject;
}

export const doesContainDigitalProduct = (items) => {
  if (items?.length === 1 && items[0]?.id === 4) {
    return true
  } else {
    return false;
  }
}


export const getAnchorLinkPrivacyPolicy = (language) => {
  switch (language) {
    case "en": return "https://pay.amazon.com/help/201751600";
    case "de": return "https://pay.amazon.de/help/201212490";
    case "fr": return "https://pay.amazon.fr/help/201212490";
    case "it": return "https://pay.amazon.it/help/201212490";
    case "es": return "https://pay.amazon.es/help/201212490";
    case "jp": return "https://pay.amazon.co.jp/help/201212490";
    default: return "https://pay.amazon.com/help/201751600";
  }
}

// Return URL constants:
export const BASE_RETURN_URL = 'https://' + window.location.host + '/demosite/#';
export const ACCOUNT_PAGE_RETURN_URL = BASE_RETURN_URL + '/account';
export const CONFIRM_PAGE_RETURN_URL = BASE_RETURN_URL + '/confirm';
export const REVIEW_PAGE_RETURN_URL = BASE_RETURN_URL + '/review';


// Return default locale based on the URL serving
// This function will return all possible locales supported - us, fr, it, de, es, eu, uk, jp
export const getDefaultLocale = () => {
  const currentURL = window.location.host;
  const match = currentURL.match(/fr|it|de|es|eu|uk|jp/);
  if (match) {
    return match[0];
  }
  return "us";
}

// This function will return regions supported by Amazon Pay - US, EU, UK, JP
export const getLocaleRegion = () => {
  const locale = getDefaultLocale();
  if (locale === 'fr' || locale === 'it' || locale === 'de' || locale === 'es') {
    return 'eu';
  }
  return locale;
}