import espressoMachine from 'src/assets/images/product-espressosmart-medium.jpg';
import linentowels from 'src/assets/images/product-linentowels-medium.jpg';
import beans from 'src/assets/images/product-beans-medium.jpg';
import cookingclass from 'src/assets/images/cooking-class.jpg';

export const dummyProducts = [
     {
          id: 1,
          name: "EspressoSmart Home Café",
          translationCodeName: "product_name_espresso",
          price: 149,
          type: 'type_coffee_mc',
          photo: espressoMachine,
          quantity: 0,
          uid: "6120855002001",
     },
     {
          id: 2,
          name: "CleanSmart Linen Hand Towels",
          translationCodeName: "product_name_towels",
          price: 9,
          type: 'type_kitchen',
          photo: linentowels,
          quantity: 0,
          uid: "2071729256907",
     },
     {
          id: 3,
          name: "EspressoSmart Beans",
          translationCodeName: "product_name_beans",
          price: 14.99,
          type: 'type_food',
          photo: beans,
          quantity: 0,
          uid: "1971249515724",
     },
     {
          id: 4,
          name: "Baking Course",
          translationCodeName: "product_name_bakingclass",
          price: 50,
          type: 'type_misc',
          photo: cookingclass,
          quantity: 0,
          uid: "4330488587172",
     },
];