import Currency from '../types/Currency';
import { CheckoutSessionDetails, ChargePermission, BuyerDetail } from '../types/WebCheckoutTypes';
import AWS from "aws-sdk";
import Environment from 'src/types/Environment';
const apigClientFactory = require('aws-api-gateway-client').default;

// as defined in docs below, plus HTTP status code as additional field
// https://developer.amazon.com/docs/amazon-pay-api-v2/error-handling.html
export interface IApiError {
    httpStatusCode: number;
    reasonCode: string;
    message: string;
}

export default class ApiGateway {

    protected baseUrl: string = Environment.apiGatewayUrl;
    protected region: string = '';
    protected cognitoPoolId: string = Environment.cognitoPoolId;
    protected requestHeaders = {
        'Content-Type': 'application/json'
    };
    protected method: string = 'GET';
    public apiGatewayClient;

    public createAPIClientHelper(data) {

        this.apiGatewayClient = apigClientFactory.newClient({
            invokeUrl: this.baseUrl,
            accessKey: data?.Credentials?.AccessKeyId,
            secretKey: data?.Credentials?.SecretKey,
            sessionToken: data?.Credentials?.SessionToken,
            region: 'us-east-1'
        });

    }

    public async createApiGatewayClient() {

        let self = this;
        let apiClientCreated = new Promise((resolve, reject) => {
            if (self.isCredentialExpired()) {

                AWS.config.region = 'us-east-1';
                var cognitoidentity = new AWS.CognitoIdentity();
                let params = {
                    IdentityPoolId: this.cognitoPoolId
                };

                cognitoidentity.getId((params), (err, data) => {
                    if (err) {
                        console.log(err, err.stack); // an error occurred
                        reject(err);
                    }
                    else {
                        var params = {
                            IdentityId: data.IdentityId!
                        };

                        cognitoidentity.getCredentialsForIdentity(params, function (err, data) {
                            if (err) {
                                console.log(err, err.stack); // an error occurred
                                reject(err);
                            }
                            else {
                                self.storeCredentials(data);
                                self.createAPIClientHelper(data);
                                resolve(true);

                            }
                        });
                    }
                });
            }
            else if (this.apiGatewayClient != null) {
                //if the client already exists and credentials have not expired
                resolve(true);
            }
            else {
                let credentials = this.getCredentials().data;
                self.createAPIClientHelper(credentials);
                resolve(true);
            }
        });

        return apiClientCreated;
    }

    public storeCredentials(data) {
        window.sessionStorage.setItem("cognito-credentials", JSON.stringify({ expirationTime: Date.now() + 60 * 60 * 1000, data: data }));
    }

    public getCredentials() {
        return JSON.parse(window.sessionStorage.getItem("cognito-credentials") || '{}');
    }

    isCredentialExpired() {
        var credentials = this.getCredentials();
        var expirationTime = credentials?.expirationTime;

        return expirationTime < Date.now() || expirationTime === undefined;
    }

    public setRegion(region: string) {
        this.region = (region !== undefined) ? region : '';
    }

    public async getCheckoutSession(checkoutSessionId: string): Promise<CheckoutSessionDetails> {

        await this.createApiGatewayClient();

        return new Promise((resolve, reject) => {
            var params = {
                headers: this.requestHeaders,
                queryParams: {
                    region: this.region,
                    checkoutSessionId: checkoutSessionId
                }
            };
            var pathTemplate = '/getCheckoutSession';
            this.apiGatewayClient.invokeApi({}, pathTemplate, this.method, params)
                .then((response) => {
                    let result = JSON.parse(response.data as string) as CheckoutSessionDetails;
                    resolve(result);
                    console.log("GetCheckoutSession Response:\n");
                    console.dir(result);
                }, (err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public async updateCheckoutSession(checkoutSessionId: string, currency: Currency, returnUrl: string): Promise<CheckoutSessionDetails> {
        const storedFinalTotal: string = localStorage.getItem('finalTotal') || '0.00';
        const finalTotal = currency.code === 'JPY' ? Number(Number(storedFinalTotal) * currency.factor).toFixed(0) : Number(storedFinalTotal) * currency.factor;
        await this.createApiGatewayClient();
        return new Promise((resolve, reject) => {
            var params = {
                headers: this.requestHeaders,
                queryParams: {
                    region: this.region,
                    checkoutSessionId: checkoutSessionId,
                    currencyCode: currency.code,
                    amount: finalTotal,
                    returnUrl: returnUrl
                }
            };
            var pathTemplate = '/updateCheckoutSession';
            this.apiGatewayClient.invokeApi({}, pathTemplate, this.method, params)
                .then((response) => {
                    let result = JSON.parse(response.data as string) as CheckoutSessionDetails;
                    resolve(result);
                    console.log("UpdateCheckoutSession Response:\n");
                    console.dir(result);
                }, (err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public async completeCheckoutSession(checkoutSessionId: string, currency: Currency): Promise<CheckoutSessionDetails> {
        const storedFinalTotal: string = localStorage.getItem('finalTotal') || '0.00';
        const finalTotal = currency.code === 'JPY' ? Number(Number(storedFinalTotal) * currency.factor).toFixed(0) : Number(storedFinalTotal) * currency.factor;
        const url = `${this.baseUrl}/completeCheckoutSession?region=${this.region}&checkoutSessionId=${checkoutSessionId}&amount=${finalTotal}&currencyCode=${currency.code}`;
        await this.createApiGatewayClient();
        return new Promise((resolve, reject) => {
            var params = {
                headers: this.requestHeaders,
                queryParams: {
                    region: this.region,
                    checkoutSessionId: checkoutSessionId,
                    amount: finalTotal,
                    currencyCode: currency.code,

                }
            };
            var pathTemplate = '/completeCheckoutSession';
            this.apiGatewayClient.invokeApi({}, pathTemplate, this.method, params)
                .then((response) => {
                    let result = JSON.parse(response.data as string) as CheckoutSessionDetails;
                    resolve(result);
                    console.log("CompleteCheckoutSession Response:\n");
                    console.dir(result);
                }, (err) => {
                    // let error = (err.response) ? err.response : err;
                    let result = JSON.parse(err.response.data as string) as IApiError;
                    result.httpStatusCode = err.response.status;
                    reject(result);
                });
        });
    }

    public async getChargePermission(chargePermissionId: string): Promise<ChargePermission> {
        const url = `${this.baseUrl}/getChargePermission?region=${this.region}&chargePermissionId=${chargePermissionId}`;
        await this.createApiGatewayClient();
        return new Promise((resolve, reject) => {
            var params = {
                headers: this.requestHeaders,
                queryParams: {
                    region: this.region,
                    chargePermissionId: chargePermissionId
                }
            };
            var pathTemplate = '/getChargePermission';
            this.apiGatewayClient.invokeApi({}, pathTemplate, this.method, params)
                .then((response) => {
                    let result = JSON.parse(response.data as string) as ChargePermission;
                    resolve(result);
                    console.log("getChargePermission Response:\n");
                    console.dir(result);
                }, (err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public async getBuyer(buyerToken: string): Promise<BuyerDetail> {
        const url = `${this.baseUrl}/getBuyer?region=${this.region}&buyerToken=${buyerToken}`;
        await this.createApiGatewayClient();
        return new Promise((resolve, reject) => {
            var params = {
                headers: this.requestHeaders,
                queryParams: {
                    region: this.region,
                    buyerToken: buyerToken
                }
            };
            var pathTemplate = '/getBuyer';
            this.apiGatewayClient.invokeApi({}, pathTemplate, this.method, params)
                .then((response) => {
                    let result = JSON.parse(response.data as string) as BuyerDetail;
                    resolve(result);
                }, (err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public async getApplicationConfig(url): Promise<any> {
        let queryParams = new URLSearchParams(new URL(url).search);
        let region = queryParams.get('region');

        await this.createApiGatewayClient();
        return new Promise((resolve, reject) => {
            var params = {
                headers: this.requestHeaders,
                queryParams: {
                    region: region,
                }
            };
            var pathTemplate = '/getApplicationConfig';
            this.apiGatewayClient.invokeApi({}, pathTemplate, this.method, params)
                .then((response) => {
                    let result = response;
                    resolve(result);
                }, (err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public async generateButtonSignature(url): Promise<any> {

        const queryObject = {};
        const params = new URLSearchParams(new URL(url).search);

        // iterate over all keys to create query
        for (const key of params.keys()) {
            queryObject[key] = params.get(key);
        }

        await this.createApiGatewayClient();
        return new Promise((resolve, reject) => {
            var params = {
                headers: this.requestHeaders,
                queryParams: queryObject
            };
            var pathTemplate = '/generateButtonSignature';
            this.apiGatewayClient.invokeApi({}, pathTemplate, this.method, params)
                .then((response) => {
                    let result = JSON.stringify(response.data) as string
                    resolve(result);
                }, (err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }
}
