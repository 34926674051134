class Currency {

  code: string;
  symbol: string;
  factor: number; // rough factor of this currency vs USD (used for prices on site for example)

  constructor(code: string, symbol: string, factor?: number) {
    this.code = code;
    this.symbol = symbol;
    this.factor = factor || 1;
  }


}

export default Currency;