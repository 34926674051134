import React, { Suspense } from 'react';
import { HashRouter } from 'react-router-dom';
import NavigationBar from './navigation/NavigationBar';
import Footer from './navigation/Footer/Footer';
import Routes from './navigation/Routes';
import LanguageNav from './navigation/LanguageNav';
import ProductsContextProvider from '../contexts/ProductsContext';
import CartContextProvider from '../contexts/CartContext';

export class App extends React.Component {
    render() {
        return (
            <ProductsContextProvider>
                <CartContextProvider>
                    <HashRouter basename={'/'}>
                        <Suspense fallback="loading">
                            <LanguageNav />
                            <NavigationBar />
                            <Routes />
                            <Footer />
                        </Suspense>
                    </HashRouter>
                </CartContextProvider>
            </ProductsContextProvider>

        );
    }
}

export default App;