export default class Environment {

    private static _hostField: string = window.location.host;

    protected static set hostField(value: string) {
        Environment._hostField = value;
    };

    protected static get hostField(): string {
        return Environment._hostField;
    };

    public static readonly prodEnvironmentBaseUrl: string = 'checkout.pay.amazon.dev';
    public static readonly prodEnvironmentBaseUrlMarketingSite: Array<string> = ['pay.amazon.com', 'pay.amazon.co.uk','pay.amazon.de', 'pay.amazon.fr', 'pay.amazon.it', 'pay.amazon.es', 'pay.amazon.eu', 'pay.amazon.co.jp'];
    public static readonly prodEnvironmentApiGateWayUrl: string = 'https://a3xys3ni34.execute-api.us-east-1.amazonaws.com/prod';
    public static readonly devoEnvironmentApiGateWayUrl: string = 'https://i8u0lwuox5.execute-api.us-east-1.amazonaws.com/prod';
    public static readonly prodCognitoPoolId: string = 'us-east-1:9027d0dd-5242-4a6b-8d5d-07b99296dd4a';
    public static readonly devoCognitoPoolId: string = 'us-east-1:c53c4371-9ca0-4810-8a22-b72f99798540';

    public static get apiGatewayUrl(): string {
        if (Environment.hostField === Environment.prodEnvironmentBaseUrl || Environment.prodEnvironmentBaseUrlMarketingSite.includes(Environment.hostField)) {
            return Environment.prodEnvironmentApiGateWayUrl;
        } else {
            return Environment.devoEnvironmentApiGateWayUrl;
        }
    };

    public static get cognitoPoolId(): string {
        if (Environment.hostField === Environment.prodEnvironmentBaseUrl || Environment.prodEnvironmentBaseUrlMarketingSite.includes(Environment.hostField)) {
            return Environment.prodCognitoPoolId;
        } else {
            return Environment.devoCognitoPoolId;
        } 
    };

}