import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import ApiGateway from '../../helpers/ApiGateway';
import styled from 'styled-components';
import { ArrowIosBackOutline as LeftArrow } from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import { ArrowIosForwardOutline as RightArrow } from '@styled-icons/evaicons-outline/ArrowIosForwardOutline';
import Address from '../../components/checkout/Address';
import pay from 'src/assets/images/paylogo.png';
import Currencies from '../../types/Currencies';
import { CheckoutSessionDetails } from '../../types/WebCheckoutTypes';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../contexts/CartContext';
import useAmazonPayScript from '../../custom-hooks/useAmazonPayScript';
import useConfig from '../../custom-hooks/useConfig';
import APayAPBButton from '../../components/amazon-pay-button/APayAPBButton';
import UrlHepler from '../../helpers/UrlHelper';
import { refreshPage } from '../../helpers/customHelperFunctions';
import { doesContainDigitalProduct } from '../../helpers/customHelperFunctions';
import { BillingAddressRetrieveFromCheckoutSession } from '../../components/checkout/AddressRetriever';
import { ShippingAddressRetrieveFromCheckoutSession } from '../../components/checkout/AddressRetriever';
import Languages from 'src/types/Languages';
import OrderSummary from '../../helpers/OrderSummary';
import { CONFIRM_PAGE_RETURN_URL } from 'src/helpers/customHelperFunctions';
import CreateSBBuyer from 'src/helpers/CreateSBBuyer';

declare const amazon: any

export default function Review() {

    const location = useLocation<{ details: string | undefined }>();
    const paymentFlow = new URLSearchParams(useLocation().search).get("flow") ?? "";

    const { t } = useTranslation();

    const currency = Currencies.getCurrentCurrency();

    const [configStatus, config] = useConfig();
    const amazonPayScriptStatus = useAmazonPayScript();
    const [changeLinksBound, setChangeLinksBound] = useState(false);
    const [isPaySelected, setIsPaySelected] = useState(false);
    const [checkoutSessionId, setCheckoutSessionId] = useState('');
    const [checkoutSessionData, setCheckoutSessionData] = useState(null as CheckoutSessionDetails);
    const [region, setRegion] = useState('');

    const [api] = useState(new ApiGateway());

    const cart = useContext(CartContext);

    var apbAddressDetails: any = location.state ? JSON.parse(location.state.details) : JSON.parse(window.sessionStorage.getItem('apbAddressDetails'));
    const language = Languages.getCurrentLanguage()?.twoLetterCode;

    useEffect(
        () => {
            if (configStatus === 'ready') {
                api.setRegion(config.region);
                setRegion(config.region);
            }
        }, [configStatus]
    )

    useEffect(
        () => {
            // if CheckoutSessionId is empty, try to get it from the URL
            if (checkoutSessionId === '') {
                let id = UrlHepler.getUrlParam("amazonCheckoutSessionId");
                if (id === "") {
                    if (paymentFlow === "APB") {
                        setIsPaySelected(true);
                    }
                    else {
                        setIsPaySelected(false);
                    }
                } else {
                    setCheckoutSessionId(id);
                    setIsPaySelected(true);
                }
            } else {
                // else, use the ID to retrieve CheckoutSession details, unless it's running already or data is already available
                if (configStatus === 'ready' && checkoutSessionData == null) {
                    api.getCheckoutSession(checkoutSessionId).then(details => {
                        console.log("Get Checkout Session details", details);
                        setCheckoutSessionData(details);
                    }).catch(reason => {
                        console.log("Error in getCheckout Session", reason);
                    });
                }

                // bind the change links
                if (amazonPayScriptStatus == 'ready' && !changeLinksBound) {
                    amazon.Pay.bindChangeAction("#changeAddress-review", {
                        amazonCheckoutSessionId: checkoutSessionId,
                        changeAction: 'changeAddress'
                    });
                    amazon.Pay.bindChangeAction("#changePayment-ap", {
                        amazonCheckoutSessionId: checkoutSessionId,
                        changeAction: 'changePayment'
                    });
                    setChangeLinksBound(true);
                }
            }
        }, [configStatus, checkoutSessionData]
    )

    function showPaymentMethod(section: string) {
        if (section == 'amazonPay' && isPaySelected) {
            return 'show';
        }
        if (section == 'creditCard' && !isPaySelected) {
            return 'show';
        }
        return 'collapse';
    }

    function getPaymentDescriptor(checkoutSesssion: CheckoutSessionDetails) {
        if (checkoutSesssion?.paymentPreferences?.length > 0) {
            if (checkoutSesssion.paymentPreferences[0].paymentDescriptor?.length > 0)
                return <React.Fragment>{checkoutSesssion.paymentPreferences[0].paymentDescriptor}<br /></React.Fragment>
        }
        return "Your selected Amazon payment method"; // todo: translate this fallback
    }

    const FetchAPBAddress = () => {
        return (
            <Address region={region}
                name={(apbAddressDetails?.firstName && apbAddressDetails?.lastName && apbAddressDetails?.firstName.length > 0) ? (currency.code === 'JPY' ? apbAddressDetails?.lastName + " " + apbAddressDetails?.firstName : apbAddressDetails?.firstName + " " + apbAddressDetails?.lastName) : 'Test Checkout'}
                addressLine1={(apbAddressDetails?.streetAddress && apbAddressDetails?.streetAddress.length > 0) ? apbAddressDetails?.streetAddress : '123 Main St'}
                city={(apbAddressDetails?.city && apbAddressDetails?.city.length > 0) ? apbAddressDetails?.city : 'Seattle'}
                stateOrRegion={(apbAddressDetails?.stateOrRegion && apbAddressDetails?.stateOrRegion.length > 0) ? apbAddressDetails?.stateOrRegion : 'WA'}
                countryCode={(apbAddressDetails?.countryCode && apbAddressDetails?.countryCode.length > 0) ? apbAddressDetails?.countryCode : 'US'}
                postalCode={(apbAddressDetails?.postalCode && apbAddressDetails?.postalCode.length > 0) ? apbAddressDetails?.postalCode : '98121'}
                phoneNumber={(apbAddressDetails?.phoneNumber && apbAddressDetails?.phoneNumber.length > 0) ? apbAddressDetails?.phoneNumber : '+11234567890'} />
        )
    }

    const ReviewCart = () => {
        if (paymentFlow === "APB") {
            return (
                <Row>
                    {
                        cart.cartItems.map(product => (
                            <React.Fragment key={product.id}>
                                <Col className="col-sm-2 col-4">
                                    <Image className="pull-left" src={product.photo} width="90" height="90" />
                                </Col>
                                <Col className="col-sm-2 col-6">
                                    <b>{t(product.translationCodeName)}</b><br /><small>#{product.uid}</small>
                                </Col>
                                <Col className="d-sm-none d-inline col-1">
                                    <button
                                        onClick={() => { cart.removeProduct(product); refreshPage(); }}
                                        className="btn btn-md p-0">
                                        <i className="fa fa-window-close fa-2x" aria-hidden="true"></i>
                                    </button>
                                </Col>
                                <Col className="col-sm-3 col-6">
                                    {
                                        product.quantity > 1 &&
                                        <button
                                            onClick={() => { cart.decrease(product); refreshPage(); }}
                                            className="btn btn-md p-0 p-sm-1">
                                            <i className="fa fa-minus-square fa-2x" aria-hidden="true"></i>
                                        </button>
                                    }
                                    {
                                        product.quantity === 1 &&
                                        <button
                                            onClick={() => { cart.removeProduct(product); refreshPage(); }}
                                            className="btn btn-md p-0 p-sm-1">
                                            {/* <TrashIcon width={"20px"} /> */}
                                            <i className="fa fa-trash fa-2x" aria-hidden="true"></i>
                                        </button>
                                    }
                                    &nbsp; <b>{product.quantity}</b> &nbsp;
                                    <button
                                        onClick={() => { cart.increase(product); refreshPage(); }}
                                        className="btn btn-md p-0 p-sm-1">
                                        <i className="fa fa-plus-square fa-2x" aria-hidden="true"></i>
                                    </button>
                                </Col>
                                <Col className="d-none d-sm-inline col-sm-2">
                                    {currency.symbol} {currency.code === "JPY" ? Number(product.price * currency.factor).toFixed(0) : Number(product.price * currency.factor).toFixed(2)}
                                </Col>
                                <Col className="col-sm-2 col-6">
                                    <strong>{currency.symbol} {currency.code === "JPY" ? Number(product.price * currency.factor * product.quantity).toFixed(0) : Number(product.price * currency.factor * product.quantity).toFixed(2)}</strong>
                                </Col>
                                <Col className="d-none d-sm-inline col-1">
                                    <button
                                        onClick={() => { cart.removeProduct(product); refreshPage(); }}
                                        className="btn btn-md">
                                        <i className="fa fa-window-close fa-2x" aria-hidden="true"></i>
                                    </button>
                                </Col>
                            </React.Fragment>
                        ))
                    }
                </Row>
            )
        } else {
            return (
                <Row>
                    {
                        cart.cartItems.map(product => (
                            <React.Fragment key={product.id}>
                                <Col className="col-sm-2 col-4">
                                    <Image className="pull-left" src={product.photo} width="90" height="90" />
                                </Col>
                                <Col className="col-sm-2 col-6">
                                    <b>{t(product.translationCodeName)}</b><br /><small>#{product.uid}</small>
                                </Col>
                                <Col className="col-sm-3 col-6">
                                    <b style={{ alignItems: 'center', display: 'flex' }}><span className="d-sm-none d-inline">{t('quantity')}:</span>{product.quantity}</b>
                                </Col>
                                <Col className="col-sm-2 col-3">
                                    {currency.symbol} {currency.code === "JPY" ? Number(product.price * currency.factor).toFixed(0) : Number(product.price * currency.factor).toFixed(2)}
                                </Col>
                                <Col className="col-3">
                                    <strong>{currency.symbol} {currency.code === "JPY" ? Number(product.price * currency.factor * product.quantity).toFixed(0) : Number(product.price * currency.factor * product.quantity).toFixed(2)}</strong>
                                </Col>
                            </React.Fragment>
                        ))
                    }
                </Row>
            )
        }
    }

    return (
        <Container className="main"><br />
            <Row className="justify-content-start offset-mt-2 mt-2">
                <Anchor to="/checkout"><ArrowL /> {t('back_to_checkout')}</Anchor>
            </Row><br />
            <Row>
                <Col className="col-sm-8 col-12">
                    <Row>
                        <Col><h3>{t('review_order')}</h3></Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4 mt-2">
                <Col className="col-sm-8 col-12">
                    <Row>
                        <Col><span className="font-weight-bold">{t('checkout_terms_conditions')}</span></Col>
                    </Row>
                    <Row>
                        {
                            language === 'jp' ?
                                <Col><Anchor to="#"> {t('checkout_review_policy_2')}</Anchor> {t('checkout_review_policy_1')} </Col>
                                :
                                <Col>{t('checkout_review_policy_1')} <Anchor to="#">{t('checkout_review_policy_2')}</Anchor></Col>
                        }
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <input type="checkbox" checked onChange={e => { }} /> &nbsp;
                            {
                                language === 'jp' ?
                                    <React.Fragment>
                                        <Anchor to="#">{t('checkout_agreed_terms_2')}</Anchor>{t('checkout_agreed_terms_1')}  {t('checkout_agreed_terms_3')}
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {t('checkout_agreed_terms_1')} <Anchor to="#">{t('checkout_agreed_terms_2')}</Anchor>  {t('checkout_agreed_terms_3')}
                                    </React.Fragment>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Shipping Address Selection: */}
            {
                paymentFlow === "APB" ? doesContainDigitalProduct(cart.cartItems) === true ?
                    /* IF APB + DIGITAL -> SHOW NOTHING */
                    <React.Fragment /> :
                    /* IF APB + PHYSICAL -> SHOW ONLY ADDRESS SELECTED IN PREVIOUS */
                    (<React.Fragment>
                        <Row className="justify-content-start offset-mt-2">
                            <Col className="col-sm-4 col-12 mt-2 border-bottom mr-4 font-weight-bold text-uppercase">{t('shipping_address')}</Col>
                        </Row>
                        <Row className="justify-content-start offset-mt-2">
                            <Col className="col-sm-4 col-12 mt-2 mr-4">
                                <FetchAPBAddress />
                                <Anchor to="/checkout" id="changeAddress-review">{t('change_shipping_address')}<ArrowR /></Anchor>
                            </Col>
                        </Row>
                        <Row className="justify-content-start offset-mt-2 mt-4">
                            <Col className="col-sm-8 col-12 mt-2 border-bottom mr-4 font-weight-bold text-uppercase">{t('shipping_method')}</Col>
                        </Row>
                        <Row className="justify-content-start offset-mt-2">
                            <Col className="col-sm-4 col-12 mt-2">{t('current_selection')}: {t('standard_cap_letter')}<br /><DoNothingAnchor href={window.location.href}>{t('change_shipping_method')}<ArrowR /></DoNothingAnchor></Col>
                        </Row>
                    </React.Fragment>) :
                    doesContainDigitalProduct(cart.cartItems) === true ?
                        /* IF EXPRESS + DIGITAL -> SHOW ONLY BILLING ADDRESS FROM THE CXSESSION */
                        <React.Fragment>
                            <Row className="justify-content-start offset-mt-2">
                                <Col className="col-sm-4 col-12 mt-2 border-bottom font-weight-bold text-uppercase">{t('billing_address')}</Col>
                            </Row>
                            <Row className="justify-content-start offset-mt-2">
                                <Col className="col-sm-4 col-12 mt-2">
                                    <BillingAddressRetrieveFromCheckoutSession checkoutSessionData={checkoutSessionData} region={region} />
                                    <DoNothingAnchor href={window.location.href}>{t('change_billing_address')}<ArrowR /></DoNothingAnchor>
                                    <Anchor to="/checkout" id="changeAddress-review"></Anchor>
                                </Col>
                            </Row>
                        </React.Fragment> :
                        // IF EXPRESS + PHYSICAL -> SHOW EVERYTHIONG
                        <React.Fragment>
                            <Row className="justify-content-start offset-mt-2">
                                <Col className="col-sm-4 col-12 mt-2 border-bottom mr-4 font-weight-bold text-uppercase">{t('shipping_address')}</Col>
                                <Col className="col-4 d-sm-block d-none mt-2 border-bottom font-weight-bold text-uppercase">{t('billing_address')}</Col>
                            </Row>
                            <Row className="justify-content-start offset-mt-2">
                                <Col className="col-sm-4 col-12 mt-2 mr-4">
                                    <ShippingAddressRetrieveFromCheckoutSession checkoutSessionData={checkoutSessionData} region={region} />
                                    <Anchor to="#" id="changeAddress-review" >{t('change_shipping_address')}<ArrowR /></Anchor>
                                </Col>
                                <Col className="col-12 d-sm-none d-block mt-2 border-bottom font-weight-bold text-uppercase">{t('billing_address')}</Col>
                                <Col className="col-sm-4 col-12 mt-2">
                                    <BillingAddressRetrieveFromCheckoutSession checkoutSessionData={checkoutSessionData} region={region} />
                                    <DoNothingAnchor href={window.location.href}>{t('change_billing_address')}<ArrowR /></DoNothingAnchor>
                                </Col>
                            </Row>
                            <Row className="justify-content-start offset-mt-2 mt-4">
                                <Col className="col-sm-8 col-12 mt-2 border-bottom mr-4 font-weight-bold text-uppercase">{t('shipping_method')}</Col>
                            </Row>
                            <Row className="justify-content-start offset-mt-2">
                                <Col className="col-sm-4 col-12 mt-2">{t('current_selection')}: {t('standard_cap_letter')}<br /><DoNothingAnchor href={window.location.href}>{t('change_shipping_method')}<ArrowR /></DoNothingAnchor></Col>
                            </Row>
                        </React.Fragment>
            }

            <Row className="justify-content-start offset-mt-2 mt-4">
                <Col className="col-sm-8 col-12 mt-2 border-bottom mr-4 font-weight-bold text-uppercase">{t('payment_method')}</Col>
            </Row>
            <div className={`multi-collapse collapse ${showPaymentMethod('creditCard')}`} id="paymentOptions">
                <Row className="justify-content-start offset-mt-2">
                    <Col className="col-4 mt-2 ml-4 mb-2"><input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" defaultChecked /><label className="form-check-label" htmlFor="exampleRadios1">{t('credit_card_debit_card')}</label></Col>
                    <Col className="col-2 mt-2 mb-2"></Col>
                </Row>
                <Row className="justify-content-start offset-mt-2">
                    <Col className="col-4 mt-2 ml-4"><input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" data-toggle="collapse" data-target=".multi-collapse" aria-expanded="false" aria-controls="paymentOptions paymentSelection" /><label className="form-check-label" htmlFor="exampleRadios2">Amazon Pay</label></Col>
                    <Col className="col-4 mt-2"><Image src={pay} height="20" /></Col>
                </Row>
            </div>
            <Row className={`justify-content-start offset-mt-2 multi-collapse ${showPaymentMethod('amazonPay')}`} id="paymentSelection">
                <Col className="col-sm-4 col-12 mt-2">
                    {
                        paymentFlow === "APB" ?
                            <React.Fragment>
                                <Form.Check
                                    type='radio'
                                    id="somethig"
                                    label={<Image src={pay} height="20" />}
                                    checked
                                    onChange={e => { }}
                                />
                                <Form.Check
                                    type='radio'
                                    id="somethig"
                                    label={t('credit_card')}
                                    checked={false}
                                    onChange={e => { }}
                                />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {t('current_selection')}: Amazon Pay<br />
                                <Anchor to="#" id="changePayment-ap">{t('change_payment_method')}<ArrowR /></Anchor><br />
                                <Anchor to="#" className={isPaySelected ? 'd-none' : ''} data-toggle="collapse" data-target=".multi-collapse" aria-expanded="false" aria-controls="paymentOptions paymentSelection">{t('change_payment_selection')}<ArrowR /></Anchor>
                            </React.Fragment>
                    }
                </Col>
                {
                    paymentFlow != "APB" &&
                    <Col className="col-4 mt-2">{getPaymentDescriptor(checkoutSessionData)}</Col>
                }
            </Row>
            <Row className="justify-content-start offset-mt-2 mt-4">
                <Col className="col-sm-8 col-12">
                    <hr />
                    <Row>
                        <Col className="d-none d-sm-inline col-4 font-weight-bold text-uppercase">{t('product')}</Col>
                        <Col className="d-none d-sm-inline col-3 font-weight-bold text-uppercase">{t('quantity')}</Col>
                        <Col className="d-none d-sm-inline col-2 font-weight-bold text-uppercase">{t('item_price')}</Col>
                        <Col className="d-none d-sm-inline col-3 font-weight-bold text-uppercase">{t('subtotal')}</Col>
                    </Row>
                    <hr />
                    <ReviewCart />
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col className="col-sm-4 col-12">
                    <OrderSummary orderDetails={cart} />
                    <br />
                </Col>
            </Row>
            <Row className="mt-4">
                {
                    paymentFlow === "APB" &&
                    <Col className="col-sm-3 col-12">
                        <APayAPBButton id="ap-button-product" params={apbAddressDetails} />
                        <br />
                        <CreateSBBuyer />
                    </Col>
                }
                {
                    paymentFlow != "APB" &&
                    <Col className="col-sm-2 col-12"><Link to={getSubmitLink()} onClick={() => onOrderSubmit()}><Button className="text-uppercase">{t('submit_order')}</Button></Link></Col>
                }
            </Row>
        </Container>
    )

    function onOrderSubmit() {
        if (checkoutSessionId.length > 0) {
            const returnUrl = encodeURIComponent(CONFIRM_PAGE_RETURN_URL);
            api.updateCheckoutSession(checkoutSessionId, currency, returnUrl).then(details => {
                if (details.webCheckoutDetails.amazonPayRedirectUrl.length > 0) {
                    window.location.href = details.webCheckoutDetails.amazonPayRedirectUrl;
                }
            }).catch(reason => {
                console.log(reason);
            });
        }
    }

    function getSubmitLink() {
        if (checkoutSessionId.length > 0) {
            return `#`;
        }
        return "/confirm";
    }
}

const ArrowR = styled(RightArrow)`
    color: black;
    height: 30px;
    width: 30px;
`;
const ArrowL = styled(LeftArrow)`
    color: black;
    height: 30px;
    width: 30px;
`;
const Anchor = styled(Link)`
    color: black;
    text-color: black;
    font-weight: bold;
`;
const Button = styled.button`
    background: black;
    color:  white;
    text-color: white;
    width: 100%;
    height: 50px;
    font-size: 1em;
    // margin: 0em;
    // padding: 0.25em 1em;
    border: 1px solid black;
    border-radius: 3px;
`;
const DoNothingAnchor = styled.a`
    color: black
`;
